import React from 'react';

import JourneyView from '../components/journeyview/JourneyView';

const SessionPage = () => (
  <>
    <JourneyView />
  </>
);

export { SessionPage as default };
