import React, { ReactNode } from 'react';

import classes from './Layout.module.css';

interface LayoutProps {
  children: ReactNode;
}
const Layout = ({ children }: LayoutProps) => (
  <>
    <main className={classes.main}>{children}</main>
  </>
);

export default Layout;
