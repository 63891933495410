import React from 'react';

import styles from './Modal.module.scss';

export interface ModalActionProps {
  actionText: string;
  actionIsPrimary: boolean;
  actionHandler: () => void;
}
/** A single configurable modal action button. */
const ModalAction = ({
  actionText,
  actionIsPrimary,
  actionHandler,
}: ModalActionProps) => (
  <button
    type="button"
    className={actionIsPrimary ? styles.btn : `${styles.btn} ${styles.btnAlt}`}
    onClick={actionHandler}
  >
    {actionText}
  </button>
);

export default ModalAction;
