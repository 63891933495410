import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import ConclusionList from '../../components/admin/conclusionlist/ConclusionList';
import NewConclusionForm from '../../components/admin/newconclusionform/NewConclusionForm';
import Box from '../../components/box/Box';
import { newCacheBuster } from '../../util/math';
import paths, { EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const ListConclusionsPage = () => {
  const { engineId } = useParams<EngineParams>();
  const [listCacheBuster, setListCacheBuster] = useState(newCacheBuster());

  function handleCreateNewConclusion() {
    setListCacheBuster(newCacheBuster());
  }

  return (
    <>
      <NewConclusionForm
        engineId={engineId}
        conclusionId={null}
        onCreateNewConclusion={handleCreateNewConclusion}
        onEditExistingConclusion={() => {}}
        navigateOnSubmit={null}
      />
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
        </p>
        <h1>Conclusions</h1>
        <p />
        <Link
          to={paths.newConclusion(engineId)}
          className={styles.btn}
          role="button"
        >
          New conclusion
        </Link>

        <p />
        <ConclusionList engineId={engineId} cacheBuster={listCacheBuster} />
        <p />
      </Box>
    </>
  );
};

export { ListConclusionsPage as default };
