import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import NewQuestionForm from '../../components/admin/newquestionform/NewQuestionForm';
import QuestionList from '../../components/admin/questionlist/QuestionList';
import Box from '../../components/box/Box';
import { newCacheBuster } from '../../util/math';
import paths, { EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const ListQuestionsPage = () => {
  const { engineId } = useParams<EngineParams>();
  const [listCacheBuster, setListCacheBuster] = useState(newCacheBuster());

  function handleCreateNewQuestion() {
    setListCacheBuster(newCacheBuster());
  }

  return (
    <>
      <NewQuestionForm
        engineId={engineId}
        questionId={null}
        onCreateNewQuestion={handleCreateNewQuestion}
        onEditExistingQuestion={() => {}}
        navigateOnSubmit={null}
      />
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
        </p>
        <h1>Questions</h1>
        <p />
        <Link
          to={paths.newQuestion(engineId)}
          className={styles.btn}
          role="button"
        >
          New question
        </Link>
        <p />
        <QuestionList engineId={engineId} cacheBuster={listCacheBuster} />
        <p />
      </Box>
    </>
  );
};

export { ListQuestionsPage as default };
