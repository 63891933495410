import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Engine } from '../../../api/model/Journey';
import EngineAdminService from '../../../api/service/admin/EngineAdminService';
import useService from '../../../hooks/UseService';
import paths from '../../../util/paths';
import styles from './EngineList.module.css';

const EngineList = () => {
  const [config] = useState(EngineAdminService.listEngines());
  const { data, error, loading } = useService<Engine[]>({
    config,
  });
  const engineList = data;

  if (loading) {
    return (
      <div className={styles.EngineList}>
        <p>Loading engines...</p>
      </div>
    );
  }
  if (error || engineList === undefined) {
    return (
      <div className={styles.EngineList}>
        <p className="error">Error: {error?.message}</p>
      </div>
    ); // TODO(robert): Generalise error styling
  }
  return (
    <div className={styles.EngineList}>
      {engineList.map((engine) => (
        <p key={engine.engineId}>
          <Link to={paths.viewEngine(engine.engineId)}>
            {engine.engineId}, {engine.engineName}
          </Link>
        </p>
      ))}
      {engineList.length === 0 && <p>No engines</p>}
    </div>
  );
};

export default EngineList;
