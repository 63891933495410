import React from 'react';

import { Link } from 'react-router-dom';

import Box from '../components/box/Box';
import paths from '../util/paths';

const DefaultPage = () => (
  <Box>
    <h1>Woah there</h1>
    <p>
      We like you and all, but we weren&apos;t expecting you to be here. At
      least, not at this exact page...
    </p>
    <h2>How can we help?</h2>
    <p>
      If you got here via a link on this site, we made a mistake! Please
      consider dropping us an E-Mail to let us know - we&apos;ll say thank you.
    </p>
    <p>
      Otherwise, perhaps you would like to see{' '}
      <Link to={paths.listSessions}>your sessions</Link>?
    </p>
  </Box>
);

export { DefaultPage as default };
