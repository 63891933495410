// import { newCacheBuster } from './math';

const randomBackground = () => {
  const images = [
    '030b7d14-0631-4275-a6cd-821992982aba.jpg',
    '02dfd27b-97cc-433a-8318-bcaa9db10f2e.jpg',
    '6f4b8a08-4290-43bc-b907-0bc2c8598568.jpg',
    'bcc7383c-b9c7-42c9-99bc-6434f9f7ff09.jpg',
    '3ea4956c-cf73-4307-a489-5e4182a9ce41.jpg',
    'fed5f28f-77dc-4fa7-9a1f-ac4b922fec4f.jpg',
    'e260fb9c-9159-44e5-a730-dabcbbe4ebd8.jpg',
    'e19d02e5-00f4-419c-b9d2-1bb8d1e9cd55.jpg',
    '9708064d-4dc3-4643-af0d-cb53eb38486e.jpg',
    '5bb49d91-b30f-40bd-81c5-e82f052b48af.jpg',
    '4522f7db-0c02-4d01-8558-202eb5ade011.jpg',
    '9cd1dadc-f8d6-46de-bd49-8f4d954597e2.jpg',
    '6f487ad2-9d6a-400e-873a-a089e578bead.jpg',
    'f3a06c82-f487-423e-97eb-cc627b577dda.jpg',
    '613d5d7b-e3d7-4ade-85e4-385cd000c66c.jpg',
    'ecbab3eb-97e7-4e0b-9810-b5da6333077a.jpg',
    'ae431b8e-5fe9-4bb9-9b95-a47aca0e9670.jpg',
    '054b7b7e-7e4b-4cfd-960f-05b615bd55b3.jpg',
    '795dc4fe-e280-4fc1-9682-c45786f53ae2.jpg',
    '51fd2c70-8f8f-4dfa-aaf7-8225f28ca197.jpg',
    'd91b27c3-c5de-4f0b-81fd-77d7a482899a.jpg',
    'fceb8565-d1ba-4691-9cd3-77582305aec1.jpg',
    '5304e46d-7426-495f-b5a7-a53dbd44d324.jpg',
    '46d8e9f0-eddc-4536-b619-8b00c02a6344.jpg',
    '207bdec8-307a-4340-88e1-916a00720ddb.jpg',
    '96aa6d26-d98a-429b-9fd0-0f9fc0ad7d2d.jpg',
    '0df8009b-a8ea-4a6e-81e7-63ed8518b0b6.jpg',
    '1aa37b8f-0333-48ca-9b5e-e758ee93bcde.jpg',
    '28d8870d-0895-4739-8846-7775d8b7e9a9.jpg',
    'd4cdb1a3-970a-4e3f-9c70-c24e22f6a438.jpg',
    '305660d1-e340-49a4-9bdd-e885f125be97.jpg',
    'c38f26e4-229d-4968-922e-bdd2628e3bc2.jpg',
    'db0c96cc-deaf-49cc-b51a-65e3a01068a1.jpg',
    '457ddc0c-52a9-4a20-b761-8b210816b634.jpg',
    '51f3afef-2fbf-45f0-b43f-f7e23c67f81f.jpg',
    '04d96d55-ea6c-4973-994c-2572ed68862b.jpg',
    '3aba79b9-9228-43fc-ad12-fa9a67dacc7e.jpg',
    'fbc19ee5-17c8-4385-bef9-54ae6336abb3.jpg',
    '5be5aff8-e07c-4b36-b6f6-76a3ad3c32c3.jpg',
    '5400d3a3-1dd3-488e-b712-ec7dc8f60319.jpg',
    '0fb09f83-d0cf-4a17-9d0e-a26ff6cc9f7e.jpg',
    'a57b6eab-84cf-4d25-a403-53029f17866b.jpg',
    '0f9bece2-a526-4c2a-804b-31a78333e751.jpg',
    '675351a5-5447-4cb9-b673-bf07a681f7b2.jpg',
    'a55e52a1-6f96-417a-9ebe-04ad3f822c4d.jpg',
    '82068322-a90b-4956-b997-d96251fd689f.jpg',
    '11cfaac4-f415-4f2b-880b-4a81d4ef4793.jpg',
    '369a57f0-7b9c-43dc-9bab-52a79445d6f2.jpg',
    '584c27da-ee8e-4c26-a801-fdff575ed4ee.jpg',
    'd380e502-08b0-47f8-ac37-c59ef11adbf1.jpg',
    '3c8e743e-7e69-474b-971d-4ce5e0f91e38.jpg',
    'd5c454bd-cc6a-491c-afd2-0201e50eeeeb.jpg',
    '2f2c3501-ddbc-403e-8ac3-4a89a277dd29.jpg',
    'f6d778ab-e139-4637-9e72-b0156f2ed567.jpg',
    'c6f9a03d-9e71-4002-88ae-b87a3e5c1777.jpg',
    '316d3ba8-28c6-48b5-83f1-b0a65141fd57.jpg',
    '697e10f9-302f-4cdb-acb7-c813dcf36841.jpg',
    '3f74c3ee-f360-4c28-bbee-e18e5d244ed4.jpg',
    '28e37c31-e0b8-4f73-9a6c-385e0081ad23.jpg',
    '155cfb43-0719-4664-b6b6-9f3bfe12d14a.jpg',
    '8e1e114c-069d-4a2a-9b2f-618d008116d0.jpg',
    '6a26efbb-c095-45f3-993e-c689d26570c5.jpg',
    '2d712423-4b25-42b1-b9a3-630afa156f1e.jpg',
    '17f3a1e2-ceb0-48f5-9de3-2c3e3835bc2b.jpg',
    '00b8beb1-7029-408b-9f80-b1e71ee4789d.jpg',
    '2f3fe81c-bfcc-457c-8810-1a3d682cc6f2.jpg',
    '8b7c01be-c643-4be9-a085-1ed31923406f.jpg',
    '9b36cdae-fe20-4ed6-93c0-dba0c6229da4.jpg',
    'e3f78f50-ffe8-4a21-89cb-5e42388ba2e4.jpg',
    '895ca6df-9427-41ff-ba0b-37341e41d445.jpg',
    '1d7f31fc-e74a-46c8-9a1d-f1e3750f4d53.jpg',
    '94a715a2-5862-45f9-8d0c-a61042b0fc47.jpg',
    '771c1bda-9f11-4815-8652-54929f8b1a91.jpg',
    '373ccb32-00e1-45f1-b568-50dee53cf8a2.jpg',
    '95fc3e70-8022-4015-80ae-2ec21d4db5c1.jpg',
    'e6e24ad3-3a18-4044-b241-7c2b7b6d95db.jpg',
    '93f80dfd-0e2a-497f-87ae-b3068c9fac75.jpg',
    '49b27528-8590-40e2-bd8e-bbf2c0d418ff.jpg',
    '93a38216-3b67-4084-aa7c-f1592a5a3774.jpg',
    '59fad260-8d0f-4af7-ba84-14a04a7280e5.jpg',
    'e0261a85-f6d2-4122-be8c-bf5742f96f80.jpg',
    '9e9f2c54-f85a-40ad-b31f-a8dd991d0a35.jpg',
    'e0ee3075-4066-435e-a7da-4c2c1917b86b.jpg',
    '361be255-62a5-4509-9def-4611ee830164.jpg',
    'c0f746c5-031f-44ef-baa8-2b248e4c8192.jpg',
    'b3b6f275-4461-46ab-a3ac-7a32d3467a48.jpg',
    'f256cb13-1558-4722-b023-4dab06ec4e11.jpg',
    '7d9f28af-1e10-4bc3-a7f2-ab1278f85cf4.jpg',
    '2ac1266f-a739-4531-98e9-279bb8c537b6.jpg',
    'efd4b5fd-346d-45af-bcc9-d96a5b0efb25.jpg',
    'eccae93d-b7be-42f2-9b8e-055492e71b84.jpg',
    '07db6801-03e7-4011-8f0f-5a4759d37e78.jpg',
    '5a41cd1a-9fd0-436e-9f6e-5b9a64ab5bad.jpg',
    '193034eb-b6eb-40a6-b802-4883dca11dc3.jpg',
    'e94b8fbc-036f-415b-86a3-0764f7ba40b1.jpg',
    'a4b51b44-a8f2-4bb8-94c5-177a87311430.jpg',
    '189cc164-a171-4f44-8288-a8dc6992b73f.jpg',
    '4ec2b869-1e94-4af2-b199-8e4c322b2db9.jpg',
    '1afe25af-dee2-4984-9834-e7736b9cd717.jpg',
    '97da7287-a709-42f1-a384-5d6f167e7679.jpg',
    '3bd331b6-2a3b-4eb0-b0a1-293c74a4da13.jpg',
    '702231d5-a7d8-4a7c-a023-65ec1033e5c6.jpg',
    'f1492e54-a6e6-4ad0-acfb-683169d44aad.jpg',
    '2d5c6c93-6a9f-4320-aa14-fd8a9afcb477.jpg',
    '950d4d22-7f3b-471d-a23f-c14b70af7577.jpg',
    '8992d4c7-945f-4ad4-a33e-89bd816c3105.jpg',
    'aeb7c924-1a2a-424f-b066-1c32f406178f.jpg',
    '131726a1-1245-4f29-922a-05fb04b7283e.jpg',
    'c226e806-8908-4c4f-92a4-3f550045d8ed.jpg',
    '493e5d3d-bdcd-4285-a408-b219e908237c.jpg',
    '6a6f7c49-726e-498d-9189-db39f153b583.jpg',
    '0363fc8a-48ea-4bf3-ba3d-fca614fcb262.jpg',
    '0e077654-7669-42b0-a027-195d4e342d1b.jpg',
    '66ac2e67-0c89-4e2c-9d43-583e04602b20.jpg',
    'd732b62f-e95e-4ff7-8127-cbe0a1643805.jpg',
    '139124ea-d79c-4108-a84a-e99b6ae1ba6e.jpg',
    '3d5a77ed-9d01-471c-a01e-302267b357f6.jpg',
    '3d9135af-fb27-4c7b-92f2-858c9ffaaefa.jpg',
    '072b26c0-a069-478f-a844-bd69ae16cce4.jpg',
    '523c98f7-45b8-45eb-87cf-8539f654780f.jpg',
    'e6292da6-aff3-44ee-89fb-4524c3213b4e.jpg',
    '537a431d-a14b-4a21-8e0e-3efd3ed0248b.jpg',
    'b9ebfe9b-6ec4-4b20-a429-41abbea76f38.jpg',
    '87fffc6d-902e-492f-b59a-4f866026136f.jpg',
    '667acef9-3f4b-438f-91f2-645f72058e34.jpg',
    '010f8234-ad9d-438b-9243-4eb0b1d146a6.jpg',
    'a63a1db8-fd40-4369-893c-7a440e70ae75.jpg',
    '6ed6a8f4-d172-492b-b14a-43d56f5f6e54.jpg',
    '186f9a2f-f974-42b5-be58-c2f8d9f890fa.jpg',
    '7d31a510-350a-4415-8478-c7fe490746bf.jpg',
    '947fa476-4aad-4cd3-85e9-9d5595e72b1e.jpg',
    'b0f8dce5-707d-4902-ba43-d8c9e7493ef6.jpg',
    '02710817-4bf3-4494-9324-f0463e43664a.jpg',
    '48d9c7fb-20a8-405c-a4b9-88eb10a97791.jpg',
    '539ac6f5-4332-45a8-b4dc-477d7dad503b.jpg',
    '5564ee3d-9716-4dc2-b774-4d454edf6927.jpg',
    'b784c667-ca1a-4ef0-8c00-2e1178ed5f0d.jpg',
    'cfeb891b-7da3-43ae-9d94-88a87e13651b.jpg',
    '97744410-a05d-481c-a0f5-6c0ebc384080.jpg',
    '69c66d0a-2ab4-4911-9645-6f4cbb3d10b0.jpg',
    '859e323f-2990-4f43-805c-09442cf44211.jpg',
    '25393598-7f81-4903-afe6-a09f655c3465.jpg',
    '1212b091-cf85-404b-8794-ef508d0c6eb5.jpg',
    '0287252b-2e09-4a96-acc3-cc6511c683fa.jpg',
    'fb34e349-7b05-4d95-979d-1bcb3a02d79e.jpg',
    'd37bdf21-b9a3-4758-b143-88d24bd717a0.jpg',
    '24585285-f2db-4ec4-a243-4455c1526590.jpg',
    'adf367e6-5157-437d-ba6a-f6b719820320.jpg',
    '052670d4-0afc-49ef-9c2c-bcc399550ea1.jpg',
    'eab10e73-e783-4c97-9d6a-3ec1fbf8e061.jpg',
    'aee38f22-7b1f-4e1d-8b78-4a7f79377cbb.jpg',
    '14316a7b-4710-4256-939a-1f1c933535e6.jpg',
    '41ddc0ba-0b78-431e-aac0-cd86d6be33f3.jpg',
    'cc7fdc3d-9449-4a66-a104-89931e1c2b06.jpg',
    '054309bf-f7ec-4358-a611-6eefd44e1bca.jpg',
    '416bf1de-26ff-495b-82eb-3072ceffe7d0.jpg',
    '69944bb3-619c-4539-8ac6-a9715369504c.jpg',
    '74ceaa9f-e50f-4116-a8e9-e2b0f9e027cb.jpg',
    'e9110559-af0a-495e-acbd-12c1eb166c21.jpg',
    'f3567ab8-e8a8-4667-b04d-1bba484744ee.jpg',
    'cad196db-f741-48a1-86fd-0b11913a51b9.jpg',
    '3946504f-8e7b-43dc-b7d1-5b8471eed339.jpg',
    '203899ec-4073-45d1-8e5e-3de861ef2287.jpg',
    '37d0d26c-2147-4329-a3e4-a04a0a0a2b6b.jpg',
    'fb6df32d-662c-4f2a-8189-2faf314da417.jpg',
    '56497fc0-3cd1-498a-97b1-62950616f797.jpg',
    'f1b33ad8-61a6-4bbc-9cd9-5392d417da21.jpg',
    'fc876d8b-bceb-4a0c-bf7f-12d56c747d5f.jpg',
    '16e0cc7a-4bd7-4111-9efc-30ac8dfa11ee.jpg',
    'f72b2ab6-9e8b-4653-9e54-770752b82724.jpg',
    'bc80de0f-eef9-4657-bebd-640d2424fc38.jpg',
    'cfb3f853-6996-4435-a853-6a06f5c51027.jpg',
    '98ee8936-cb5f-4763-8807-b887893f790f.jpg',
    '69e91555-5374-42ea-b5b1-bbbf0d67cf24.jpg',
    'fc2c099d-4aef-4045-a9f2-cf41e55b9d87.jpg',
    '96399b0f-454f-4e04-9414-b89e9e496af7.jpg',
    '61ef5f8d-3c14-4a84-a65b-4c5ca9ca45f2.jpg',
    '82eed278-d806-4586-9bf2-2dff05d3fcf2.jpg',
    '15f8256b-d0c6-4100-b9a1-0cdcae4d3149.jpg',
    'd0320b02-ff42-4366-ae52-d7445c82b468.jpg',
    '48dc88f4-3e00-4374-9f53-5d345fd01227.jpg',
    'ca034b66-1be8-4ee3-9bea-8964d44c3504.jpg',
    'edc36abe-ec92-4288-a1e3-620e39113e38.jpg',
    'fce6b757-f194-4f74-861c-b7e06059731f.jpg',
    'd146e53e-7bf5-4715-9d6b-0f60b464ad7b.jpg',
    '4d2ad075-7f57-42e3-886e-29c3812f260e.jpg',
    '175d043e-6825-4d0a-83a6-b3868456c164.jpg',
    '1716ce2d-2bb4-43b5-b7c2-2e24fffbe72b.jpg',
    '43007825-fd40-4fb4-ae79-f60ea8b569ce.jpg',
    '9e3ea844-65f7-440b-b3d3-b4ca250429ad.jpg',
    'be873c7a-830d-4805-a6a1-a29797646f6c.jpg',
    '078c4f0d-308d-47c8-8a81-b608664299e1.jpg',
    '1e57b33d-ea28-43a7-9a00-dc246f7a5a9b.jpg',
    'bb44cb42-c078-41c3-9db4-de9a24be65e0.jpg',
    '17c6f2db-d805-4833-a812-b51e0faa83c5.jpg',
    'c2fef70c-c9c0-43bd-862e-854637096514.jpg',
    'c1459e81-e5e0-4df8-a19a-4e41d5f3862a.jpg',
    '8c2519f9-e618-496d-9a39-7a670d71704c.jpg',
    'fc091773-6569-43b4-8b73-26db81f8ca98.jpg',
    '389e1ca4-fe32-44c4-b3d2-2b2b0dc357e9.jpg',
    'a0b7ec05-54d6-46d5-bd04-299693ab6f21.jpg',
    '2ce37552-c605-4bf2-ad37-230a9e5a852e.jpg',
    'e955c093-002e-4d93-8d5a-126c4bd2b41b.jpg',
    'fa020ad5-a65c-4f11-9f8f-92f0a2a0b4d0.jpg',
    'f4418dd5-d3c7-4be4-8c71-0290e0942e95.jpg',
    '05451911-6dcd-4fc4-839d-756086125964.jpg',
    'dfca3764-dd24-4427-8401-2d84a87d1d08.jpg',
    '1d5ad57b-94c6-438c-b60c-2ff55222c9af.jpg',
    'cdcbde5a-e84c-4d56-839d-9e028e3a623c.jpg',
    '7eca9cad-1afd-49d0-be49-65f91dfd1425.jpg',
    '6e89319a-8fe1-4887-87fd-db95af7753f1.jpg',
    '89b76553-9218-4abc-934a-a390e8362b0a.jpg',
    'da50e31a-989e-4f78-ac1e-e543660cd334.jpg',
    'da68deeb-1415-4b26-93b8-9a6608a1f27c.jpg',
    '0423b773-13d9-4148-9584-2668a9ce8418.jpg',
    '2736033e-2016-4ed9-91e2-63c09d97bb0c.jpg',
    '872c3eef-197c-455e-a76d-39c5b183209d.jpg',
    '2d986712-13e8-4c10-8def-5263938d72de.jpg',
    'a31a75bf-fd5c-42ff-8a51-e7a515a0b5b2.jpg',
    '840e87f7-5599-4c4a-91d1-f5d149037457.jpg',
    '03e77053-a168-400c-85ea-97de21e30b2c.jpg',
    'c28847c3-5596-4e43-9df9-2b6cda9573ed.jpg',
    '1459f888-4846-42e0-b167-f155efdac506.jpg',
    '8f20775a-da4f-4e77-a7af-883341e432f9.jpg',
    'ad5071fb-c9d8-45c5-abc8-e2ade782d69c.jpg',
    'fa57dbc3-bfea-49e8-9f11-4b702d9bc713.jpg',
    '012b68bc-fe3d-40e2-adfb-dfcdbd586ad2.jpg',
    'b2cbf613-635f-4314-9ac5-f62f6f917935.jpg',
    'a5a5b875-fc9e-4ffa-900e-fc80b53a5fd3.jpg',
    'd3d42f13-4e5b-4ce1-9c7d-f68cb87c1876.jpg',
    '4f617058-9c42-4c28-8e2f-4d422e5a34f3.jpg',
    'efa9a0b9-23e7-4265-b4b5-19f892210b63.jpg',
    'd8aca800-3e22-49fd-bf13-f41dd5485cff.jpg',
    'c3b2f658-3062-4871-a461-7130320ef6f8.jpg',
    'efc8b294-e767-4c38-8ecb-20d50127e9b0.jpg',
    '39b35d42-68a5-4cf3-aa6c-36c80fd37616.jpg',
    '4e7bb914-968c-4ad8-81c7-19a12cf20d3a.jpg',
    '73d579cb-b6d9-4f9c-8e87-11a676c09194.jpg',
    'e3987a55-92f4-4717-95da-e8a0d79e6493.jpg',
    '3aebf306-ca93-464f-a4ec-8db0dcfefaa9.jpg',
    '87fd1c15-6c06-4f70-b680-c387370564ea.jpg',
    '7f3d68be-b2cd-4625-a612-c8b53d3ae262.jpg',
    'c04ec4c0-0133-4811-a666-792338ebcf74.jpg',
    '7165943e-339e-44fe-ac85-4c002af343c7.jpg',
    '412c564e-58a1-4e85-b3a9-bffeb320ac45.jpg',
    '53f31c33-3f33-479e-972a-048b519b42eb.jpg',
    'd73dcada-5b2c-4b29-8615-5a96316f9fc8.jpg',
    '7c493022-3321-4bbc-bcf0-ae814ef8eafb.jpg',
    'bf3dd3b7-8a89-4bb3-bfdc-7ed9ef922d32.jpg',
    '2f4526dd-3605-481c-b575-e29fcccf8cff.jpg',
    'c805767a-81e2-48a8-b7df-8283b553d255.jpg',
    'bef80ba9-041b-44d8-ad12-71ef399a0b10.jpg',
    '24b625ec-240a-4205-8201-719775c205cf.jpg',
    'b6b43ce9-a15d-4775-90d6-73ed2ac7f4fd.jpg',
    'fc8adbbb-2563-4339-b73e-6c03545f8513.jpg',
    'a7269076-fb77-496d-9af9-e52beb475235.jpg',
    '7c2475e2-b082-4b60-b436-994409f66f86.jpg',
    '7d4f0d66-27aa-4b83-96c7-b084b1f98159.jpg',
    '62c1fb4d-ad58-4f30-889d-8012a1cc1476.jpg',
    'e0e13f14-5d85-45e6-a81b-04b258b04360.jpg',
    'fe110755-f2e2-4c10-bbbd-6ad4e8ca9f30.jpg',
    '78fa8f08-608a-4898-97bc-6ac4a1d6cf24.jpg',
    'b44abd53-a5d5-48c0-9671-88fa19e9a957.jpg',
    '3481af02-3d2b-4f91-ac6d-930a5f0dd50e.jpg',
    '764046fc-746a-4fdc-9c61-aeacfae7a1f0.jpg',
    'ce16692a-eebf-47fb-8169-84b52ff299c9.jpg',
  ];
  const randomImage = images[Math.floor(Math.random() * images.length)];
  return `/static/bg/${randomImage}`; // ?cb=${newCacheBuster()}`;
  // TODO(robert): Remove cachebuster and obfuscate image names, plus add
  //  in 'bad' and 'good' groups maybe (or just stick to good ones?)
};

interface ImageInit {
  bg: string[];
}

declare global {
  interface Window {
    idpIInit: ImageInit;
  }
}

export const ensureInitBackgrounds = () => {
  window.idpIInit = window.idpIInit || {
    bg: [randomBackground(), randomBackground(), randomBackground()],
  };
};

export const initialBackgrounds = () => window.idpIInit?.bg || [];

export default randomBackground;
