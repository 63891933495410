import { apiCallConfig, ApiCallConfig } from '../../util/http';
import { Journey, JourneyPoint } from '../model/Journey';

class JourneyService {
  /** @deprecated */
  static getJourneyPoint(): ApiCallConfig<JourneyPoint> {
    return apiCallConfig('/journeys/1');
  }

  static getJourney(sessionId: string): ApiCallConfig<Journey> {
    return apiCallConfig(`/sessions/${sessionId}/journey`);
  }

  static createJourney(sessionId: string): ApiCallConfig<Journey> {
    return apiCallConfig(`/sessions/${sessionId}/journey`, 'post');
  }

  static updateJourney(
    sessionId: string,
    answerId: string
  ): ApiCallConfig<Journey> {
    return apiCallConfig(`/sessions/${sessionId}/journey/${answerId}`, 'post');
  }
}

export default JourneyService;
