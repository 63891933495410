import React from 'react';

import { useHistory } from 'react-router-dom';

import SignInModal from '../components/signin/SignInModal';

const SignInPage = () => {
  const history = useHistory();

  const closeHandler = () => {
    history.replace(`/`);
  };

  return <SignInModal closeHandler={closeHandler} />;
};

export { SignInPage as default };
