import React from 'react';

import { Link, useParams } from 'react-router-dom';

import NewQuestionForm from '../../components/admin/newquestionform/NewQuestionForm';
import Box from '../../components/box/Box';
import paths, { EngineParams, QuestionParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const ViewQuestionPage = () => {
  const { engineId } = useParams<EngineParams>();
  const { questionId } = useParams<QuestionParams>();

  function handleCreateNewQuestion() {
    // console.log('New question created');
  }

  function handleEditExistingQuestion() {
    // console.log('Existing question edited');
  }

  return (
    <>
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
          <Link to={paths.listQuestions(engineId)}>Questions</Link> /&nbsp;
        </p>
        <p>You are about to edit an existing question.</p>
      </Box>
      <NewQuestionForm
        engineId={engineId}
        questionId={questionId}
        onCreateNewQuestion={handleCreateNewQuestion}
        onEditExistingQuestion={handleEditExistingQuestion}
        navigateOnSubmit={paths.listQuestions(engineId)}
      />
    </>
  );
};

export { ViewQuestionPage as default };
