import React, { ReactElement, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Engine } from '../../api/model/Journey';
import EngineAdminService from '../../api/service/admin/EngineAdminService';
import Box from '../../components/box/Box';
import useService from '../../hooks/UseService';
import paths, { EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const ViewEnginePage = () => {
  const { engineId } = useParams<EngineParams>();

  const [config] = useState(EngineAdminService.getEngineById(engineId));
  const { data, error, loading } = useService<Engine>({
    config,
  });
  const engine = data;

  const renderContent = (content: ReactElement) => (
    <Box>
      <p className={styles.breadcrumbs}>
        <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
        <Link to={paths.listEngines}>Engines</Link> /&nbsp;
      </p>
      <h1>Engine: {engineId}</h1>
      {content}
      <p />
      <p />
    </Box>
  );

  if (loading) {
    return renderContent(
      <div className={styles.Engine}>
        <p>Loading engine...</p>
      </div>
    );
  }
  if (error || engine === undefined) {
    return renderContent(
      <div className={styles.Engine}>
        <p className="error">Error: {error?.message}</p>
      </div>
    ); // TODO(robert): Generalise error styling
  }
  return renderContent(
    <div className={styles.Engine}>
      <p>ID: {engine.engineId}</p>
      <p>Name: {engine.engineName}</p>
      <br />
      <p>
        <Link to={paths.listAnswers(engine.engineId)}>View Answers</Link>
      </p>
      <p>
        <Link to={paths.listQuestions(engine.engineId)}>View Questions</Link>
      </p>
      <p>
        <Link to={paths.listConclusions(engine.engineId)}>
          View Conclusions
        </Link>
      </p>
    </div>
  );
};

export { ViewEnginePage as default };
