import { apiCallConfig, ApiCallConfig } from '../../../util/http';
import { NewQuestionRequest } from '../../model/admin/Request';
import { Question } from '../../model/Journey';

class QuestionAdminService {
  static listQuestionsForEngine(engineId: string): ApiCallConfig<Question[]> {
    return apiCallConfig(`/engine-admin/engines/${engineId}/questions`);
  }

  static newQuestion(
    engineId: string,
    newQuestionRequest: NewQuestionRequest
  ): ApiCallConfig<Question> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/questions`,
      'post',
      newQuestionRequest
    );
  }

  static getQuestionById(
    engineId: string,
    questionId: string
  ): ApiCallConfig<Question> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/questions/${questionId}`
    );
  }

  static updateQuestionById(
    engineId: string,
    questionId: string,
    newQuestionRequest: NewQuestionRequest
  ): ApiCallConfig<Question> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/questions/${questionId}`,
      'post',
      newQuestionRequest
    );
  }
}

export default QuestionAdminService;
