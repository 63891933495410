import React from 'react';

import styles from './ImagePreview.module.css';

interface ImagePreviewProps {
  dataUri: string;
  isFullscreen: boolean;
}

export const ImagePreview = ({ dataUri, isFullscreen }: ImagePreviewProps) => {
  const classNameFullscreen = isFullscreen ? styles.ImagePreviewFullscreen : '';

  return (
    <div className={`${styles.ImagePreview} ${classNameFullscreen}`}>
      <img src={dataUri} alt="Camera preview" />
    </div>
  );
};

export default ImagePreview;
