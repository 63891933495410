import React from 'react';

import { Link } from 'react-router-dom';

import Box from '../../components/box/Box';
import paths from '../../util/paths';

const EngineAdminPage = () => (
  <Box>
    <h1>Engine Admin</h1>
    <p />
    <Link to={paths.listEngines}>View current engines</Link>
    <p />
  </Box>
);

export { EngineAdminPage as default };
