import React, { useContext, useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material';
import { Route, Switch } from 'react-router-dom';

import EngineAdminPage from '../../pages/admin/EngineAdminPage';
import ListAnswersPage from '../../pages/admin/ListAnswersPage';
import ListConclusionsPage from '../../pages/admin/ListConclusionsPage';
import ListEnginesPage from '../../pages/admin/ListEnginesPage';
import ListQuestionsPage from '../../pages/admin/ListQuestionsPage';
import NewAnswerPage from '../../pages/admin/NewAnswerPage';
import NewConclusionPage from '../../pages/admin/NewConclusionPage';
import NewEnginePage from '../../pages/admin/NewEnginePage';
import NewQuestionPage from '../../pages/admin/NewQuestionPage';
import ViewAnswerPage from '../../pages/admin/ViewAnswerPage';
import ViewConclusionPage from '../../pages/admin/ViewConclusionPage';
import ViewEnginePage from '../../pages/admin/ViewEnginePage';
import ViewQuestionPage from '../../pages/admin/ViewQuestionPage';
import AllSessionsPage from '../../pages/AllSessionsPage';
import DefaultPage from '../../pages/DefaultPage';
import NewSessionPage from '../../pages/NewSessionPage';
import SessionPage from '../../pages/SessionPage';
import SignInPage from '../../pages/SignInPage';
import MediaPrecacheContext from '../../store/MediaPrecacheContext';
import { SessionContextProvider } from '../../store/SessionContext';
import randomBackground, { initialBackgrounds } from '../../util/background';
import cpTheme from '../../util/mui-theme';
import paths from '../../util/paths';
import Layout from '../layout/Layout';
import NavBar from '../navbar/NavBar';
import styles from './Main.module.css';

/**
 * The Main component handles showing the NavBar, backdrop image, main layout for the current page, and any modals.
 * Must be rendered within a <BrowserRouter />.
 */
const Main = () => {
  const [bgImages, setBgImages] = useState({
    queue: initialBackgrounds(),
    nextIsOverlay: false,
  });
  const [oldBgImages, setOldBgImages] = useState<string[]>(bgImages.queue);
  const [overlayImage, setOverlayImage] = useState(bgImages.queue[0]);
  const [underlayImage, setUnderlayImage] = useState(bgImages.queue[1]);

  const mediaPrecacheCtx = useContext(MediaPrecacheContext);

  useEffect(() => {
    const cssTransitionTime = 60000;
    const interval = setInterval(() => {
      const newBg = randomBackground();
      setOldBgImages((arr) => arr.slice(-6).concat(newBg));
      setBgImages(({ queue, nextIsOverlay }) => ({
        queue: queue.slice(1).concat(newBg),
        nextIsOverlay: !nextIsOverlay,
      }));
    }, cssTransitionTime); // Same tempo to match CSS transition time
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    bgImages.queue.forEach((bgImg) => {
      if (!mediaPrecacheCtx.hasImage(bgImg)) {
        mediaPrecacheCtx.addImage(bgImg);
      }
    });
    if (bgImages.nextIsOverlay) {
      setOverlayImage(bgImages.queue[1]);
    } else {
      setUnderlayImage(bgImages.queue[1]);
    }
  }, [bgImages]);

  useEffect(() => {
    if (oldBgImages.length > 6) {
      mediaPrecacheCtx.removeImage(oldBgImages[0]);
    }
  }, [oldBgImages]);

  return (
    <div className={styles.Main}>
      <div
        className={styles.MainBackground}
        style={{
          backgroundImage: `url("${underlayImage}")`,
        }}
      />
      <div
        className={`${styles.MainBackground} ${styles.Overlay}`}
        style={{
          backgroundImage: `url("${overlayImage}")`,
        }}
      />
      <SessionContextProvider>
        <ThemeProvider theme={cpTheme}>
          <NavBar />
          <div className={styles.MainContent}>
            <Layout>
              <Switch>
                <Route path="/" exact>
                  <SessionPage />
                </Route>
                <Route path={paths.newSession}>
                  <NewSessionPage />
                </Route>
                <Route path={paths.listSessions}>
                  <AllSessionsPage />
                </Route>
                <Route path={paths.signIn}>
                  <SignInPage />
                </Route>

                <Route path={paths.engineAdmin} exact>
                  <EngineAdminPage />
                </Route>
                <Route path={paths.listEngines} exact>
                  <ListEnginesPage />
                </Route>
                <Route path={paths.newEngine} exact>
                  <NewEnginePage />
                </Route>
                <Route path={paths.viewEngine()} exact>
                  <ViewEnginePage />
                </Route>

                <Route path={paths.listAnswers()} exact>
                  <ListAnswersPage />
                </Route>
                <Route path={paths.listQuestions()} exact>
                  <ListQuestionsPage />
                </Route>
                <Route path={paths.listConclusions()} exact>
                  <ListConclusionsPage />
                </Route>

                <Route path={paths.newAnswer()} exact>
                  <NewAnswerPage />
                </Route>
                <Route path={paths.newQuestion()} exact>
                  <NewQuestionPage />
                </Route>
                <Route path={paths.newConclusion()} exact>
                  <NewConclusionPage />
                </Route>

                <Route path={paths.viewAnswer()} exact>
                  <ViewAnswerPage />
                </Route>
                <Route path={paths.viewQuestion()} exact>
                  <ViewQuestionPage />
                </Route>
                <Route path={paths.viewConclusion()} exact>
                  <ViewConclusionPage />
                </Route>

                <Route path="/">
                  <DefaultPage />
                </Route>
              </Switch>
            </Layout>
          </div>
        </ThemeProvider>
      </SessionContextProvider>
    </div>
  );
};

export default Main;
