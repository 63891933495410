import { apiCallConfig, ApiCallConfig } from '../../../util/http';
import { NewAnswerRequest } from '../../model/admin/Request';
import { Answer } from '../../model/Journey';

class AnswerAdminService {
  static listAnswersForEngine(engineId: string): ApiCallConfig<Answer[]> {
    return apiCallConfig(`/engine-admin/engines/${engineId}/answers`);
  }

  static newAnswer(
    engineId: string,
    newAnswerRequest: NewAnswerRequest
  ): ApiCallConfig<Answer> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/answers`,
      'post',
      newAnswerRequest
    );
  }

  static getAnswerById(
    engineId: string,
    answerId: string
  ): ApiCallConfig<Answer> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/answers/${answerId}`
    );
  }

  static updateAnswerById(
    engineId: string,
    answerId: string,
    newAnswerRequest: NewAnswerRequest
  ): ApiCallConfig<Answer> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/answers/${answerId}`,
      'post',
      newAnswerRequest
    );
  }
}

export default AnswerAdminService;
