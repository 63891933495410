import { isDev } from './env';

/** A console shim. */
class AppConsole {
  log = (message: string) => {
    if (isDev()) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  };

  error = (message: string) => {
    if (isDev()) {
      // eslint-disable-next-line no-console
      console.error(message);
    }
  };
}

const Console = new AppConsole();

export default Console;
