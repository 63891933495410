/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ReactNode, useState } from 'react';

import { Session } from '../api/model/Session';

type SessionCtx = {
  activeSession: Session | null;
  setActiveSession: (session: Session) => void;
};

const SessionContext = createContext({
  activeSession: null,
  setActiveSession: (session: Session) => {},
} as SessionCtx);

interface SessionContextProviderProps {
  children: ReactNode;
}
export const SessionContextProvider = ({
  children,
}: SessionContextProviderProps) => {
  const [storedSession, setStoredSession] = useState<Session>();

  function setActiveSession(session: Session) {
    setStoredSession(session);
  }

  const context = {
    activeSession: storedSession || null,
    setActiveSession,
  };
  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
