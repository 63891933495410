import React, { FormEvent, useEffect, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';

import {
  Answer,
  AnswerStyle,
  Conclusion,
  Question,
} from '../../../api/model/Journey';
import AnswerAdminService from '../../../api/service/admin/AnswerAdminService';
import useService from '../../../hooks/UseService';
import useTextInput from '../../../hooks/UseTextInput';
import { ApiError, makeHttpRequest, noApiCall } from '../../../util/http';
import { anyValid, notBlank } from '../../../util/validation';
import Card from '../../card/Card';
import ConclusionDropdown from './ConclusionDropdown';
import styles from './NewAnswerForm.module.scss';
import QuestionDropdown from './QuestionDropdown';

interface NewAnswerFormProps {
  engineId: string;
  answerId: string | null;
  onCreateNewAnswer: () => void;
  onEditExistingAnswer: () => void;
  /** Leave as null to reset the form instead of navigating away on submit. */
  navigateOnSubmit: string | null;
}

const NewAnswerForm = ({
  engineId,
  answerId,
  onCreateNewAnswer,
  onEditExistingAnswer,
  navigateOnSubmit,
}: NewAnswerFormProps) => {
  const presentationStyleOptions: { label: string; value: AnswerStyle }[] = [
    { label: 'Text only', value: AnswerStyle.TEXT_ONLY },
    { label: 'Text and icon', value: AnswerStyle.TEXT_AND_ICON },
    { label: 'Text and image', value: AnswerStyle.TEXT_AND_IMAGE },
  ];
  const defaultPresentationStyle = presentationStyleOptions[0];

  const [presentationStyle, setPresentationStyle] = useState(
    defaultPresentationStyle
  );

  const setPresentationStyleFromDropdown = (
    event: React.SyntheticEvent,
    value: { label: string; value: AnswerStyle } | null
  ) => {
    if (value != null) {
      // Reject blank selections
      setPresentationStyle(value);
    }
  };

  const {
    value: answerTextValue,
    isValid: answerTextIsValid,
    hasError: answerTextHasError,
    errorMessage: answerTextErrorMessage,
    valueChangeHandler: answerTextChangeHandler,
    inputBlurHandler: answerTextBlurHandler,
    reset: answerTextReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter valid answer text',
  });

  const {
    value: iconValue,
    isValid: iconIsValid,
    hasError: iconHasError,
    errorMessage: iconErrorMessage,
    valueChangeHandler: iconChangeHandler,
    inputBlurHandler: iconBlurHandler,
    reset: iconReset,
  } = useTextInput({
    validateValue: anyValid,
    defaultErrorPrompt: 'Please enter a valid icon URL',
  });

  const {
    value: imageValue,
    isValid: imageIsValid,
    hasError: imageHasError,
    errorMessage: imageErrorMessage,
    valueChangeHandler: imageChangeHandler,
    inputBlurHandler: imageBlurHandler,
    reset: imageReset,
  } = useTextInput({
    validateValue: anyValid,
    defaultErrorPrompt: 'Please enter a valid image URL',
  });

  const [nextQuestion, setNextQuestion] = useState<Question | null>(null);
  const [nextConclusion, setNextConclusion] = useState<Conclusion | null>(null);

  const formIsValid =
    answerTextIsValid &&
    iconIsValid &&
    imageIsValid &&
    (nextQuestion === null || nextConclusion === null);

  const theHistory = useHistory();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState<Answer>();
  const [submitError, setSubmitError] = useState<ApiError>();

  // Existing data loader
  const [config] = useState(
    answerId !== null
      ? AnswerAdminService.getAnswerById(engineId, answerId)
      : noApiCall()
  );
  const {
    data: existingResponse,
    error: existingError,
    loading: existingLoading,
  } = useService<Answer>({ config });

  const formDisabled = existingLoading || submitLoading;
  const formError = existingError || submitError;

  // Existing data success handler
  useEffect(() => {
    if (!existingResponse) return;

    setPresentationStyle(
      presentationStyleOptions.find(
        (value) => value.value === existingResponse.presentationStyle
      ) || defaultPresentationStyle
    );
    answerTextReset(existingResponse.text);
    iconReset(existingResponse.icon?.imageUrl);
    imageReset(existingResponse.image?.imageUrl);
    setNextQuestion(
      existingResponse.nextQuestionId === null
        ? null
        : {
            questionId: existingResponse.nextQuestionId,
            introText: '',
            questionText: '...',
            questionImage: null,
            answers: [],
            precacheImages: [],
          }
    );
    setNextConclusion(
      existingResponse.nextConclusionId === null
        ? null
        : {
            conclusionId: existingResponse.nextConclusionId,
            introText: '',
            conclusionText: '...',
            conclusionImage: null,
          }
    );
  }, [existingResponse]);

  // Submit success handler
  useEffect(() => {
    if (!submitResponse) return;

    // Notify success handler, then either navigate away or reset the form
    if (answerId !== null) {
      onEditExistingAnswer();
    } else {
      onCreateNewAnswer();
    }
    if (navigateOnSubmit) {
      theHistory.replace(navigateOnSubmit); // or push
    } else {
      setPresentationStyle(defaultPresentationStyle);
      answerTextReset();
      iconReset();
      imageReset();
      setNextQuestion(null);
      setNextConclusion(null);
    }
  }, [submitResponse]);

  function handleSubmitAnswer() {
    const icon = iconValue ? { imageUrl: iconValue } : null;
    const image = imageValue ? { imageUrl: imageValue } : null;
    const answerRequest = {
      presentationStyle: presentationStyle.value,
      text: answerTextValue,
      icon,
      image,
      nextQuestionId: nextQuestion?.questionId || null,
      nextConclusionId: nextConclusion?.conclusionId || null,
    };
    const callConfig =
      answerId !== null
        ? AnswerAdminService.updateAnswerById(engineId, answerId, answerRequest)
        : AnswerAdminService.newAnswer(engineId, answerRequest);

    makeHttpRequest<Answer>(
      callConfig,
      setSubmitLoading,
      setSubmitResponse,
      setSubmitError
    );
  }

  const formSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    handleSubmitAnswer();
  };

  return (
    <Card>
      <form className={styles.form} onSubmit={formSubmitHandler}>
        <h1>New answer</h1>
        <Autocomplete
          id="presentation_style"
          disablePortal
          sx={{ mt: 1 }}
          options={presentationStyleOptions}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={presentationStyle}
          renderInput={(params) => (
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            <TextField {...params} label="Presentation style" />
          )}
          onChange={setPresentationStyleFromDropdown}
          disabled={formDisabled}
        />
        <TextField
          id="answer_text"
          label="Answer Text"
          variant="outlined"
          fullWidth
          margin="normal"
          value={answerTextValue}
          onChange={answerTextChangeHandler}
          onBlur={answerTextBlurHandler}
          error={answerTextHasError}
          helperText={answerTextErrorMessage}
          disabled={formDisabled}
        />
        <TextField
          id="icon_url"
          label="Icon URL"
          variant="outlined"
          fullWidth
          margin="normal"
          value={iconValue}
          onChange={iconChangeHandler}
          onBlur={iconBlurHandler}
          error={iconHasError}
          helperText={iconErrorMessage}
          disabled={formDisabled}
        />
        <TextField
          id="image_url"
          label="Image URL"
          variant="outlined"
          fullWidth
          margin="normal"
          value={imageValue}
          onChange={imageChangeHandler}
          onBlur={imageBlurHandler}
          error={imageHasError}
          helperText={imageErrorMessage}
          disabled={formDisabled}
        />
        <QuestionDropdown
          fieldLabel="Next question"
          engineId={engineId}
          selectedQuestion={nextQuestion}
          onChangeQuestion={setNextQuestion}
          disabled={formDisabled}
          refreshEveryOpen={false}
        />
        <ConclusionDropdown
          fieldLabel="Next conclusion"
          engineId={engineId}
          selectedConclusion={nextConclusion}
          onChangeConclusion={setNextConclusion}
          disabled={formDisabled}
          refreshEveryOpen={false}
        />
        <div className={styles.actions}>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!formIsValid || formDisabled}
            loading={submitLoading}
            loadingPosition="end"
            endIcon={<AddCircleIcon />}
          >
            {answerId !== null ? 'Update Answer' : 'Create Answer'}
          </LoadingButton>
          {formError && <p className={styles.errorText}>{formError.message}</p>}
        </div>
      </form>
    </Card>
  );
};

export default NewAnswerForm;
