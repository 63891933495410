import * as React from 'react';
import { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { Conclusion } from '../../../api/model/Journey';
import ConclusionAdminService from '../../../api/service/admin/ConclusionAdminService';
import useService from '../../../hooks/UseService';
import { newCacheBuster } from '../../../util/math';

interface ConclusionDropdownProps {
  fieldLabel: string;
  engineId: string;
  selectedConclusion: Conclusion | null;
  onChangeConclusion: (conclusion: Conclusion | null) => void;
  disabled: boolean;
  refreshEveryOpen: boolean;
}

export default function ConclusionDropdown({
  fieldLabel,
  engineId,
  selectedConclusion,
  onChangeConclusion,
  disabled,
  refreshEveryOpen,
}: ConclusionDropdownProps) {
  const [config] = useState(
    ConclusionAdminService.listConclusionsForEngine(engineId)
  );
  const [cb, setCb] = useState(newCacheBuster());
  const updateCb = () => {
    if (refreshEveryOpen) {
      setCb(newCacheBuster());
    }
  };

  const { data, loading } = useService<Conclusion[]>({
    config,
    cacheBuster: cb,
  });
  const conclusionList = data || [];

  const setValue = (event: React.SyntheticEvent, value: Conclusion | null) => {
    onChangeConclusion(value);
  };

  return (
    <Autocomplete
      id="selected_conclusion"
      disablePortal
      sx={{ mt: 1 }}
      value={selectedConclusion}
      onChange={setValue}
      onOpen={updateCb}
      disabled={disabled}
      isOptionEqualToValue={(option, value) =>
        option.conclusionId === value.conclusionId
      }
      getOptionLabel={(option) =>
        `${option.conclusionId}: ${option.conclusionText.substring(0, 50)}`
      }
      options={conclusionList}
      loading={loading}
      renderInput={(params) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          label={fieldLabel}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
