const paths = {
  listSessions: '/sessions',
  newSession: '/new',

  signIn: '/signin',

  engineAdmin: '/ea',

  listEngines: '/ea/engines',
  newEngine: '/ea/engines/new',
  viewEngine: (engineId: string = ':engineId') => `/ea/engines/${engineId}`,

  listAnswers: (engineId: string = ':engineId') =>
    `/ea/engines/${engineId}/answers`,
  newAnswer: (engineId: string = ':engineId') =>
    `/ea/engines/${engineId}/answers/new`,
  viewAnswer: (
    engineId: string = ':engineId',
    answerId: string = ':answerId'
  ) => `/ea/engines/${engineId}/answers/${answerId}`,

  listQuestions: (engineId: string = ':engineId') =>
    `/ea/engines/${engineId}/questions`,
  newQuestion: (engineId: string = ':engineId') =>
    `/ea/engines/${engineId}/questions/new`,
  viewQuestion: (
    engineId: string = ':engineId',
    questionId: string = ':questionId'
  ) => `/ea/engines/${engineId}/questions/${questionId}`,

  listConclusions: (engineId: string = ':engineId') =>
    `/ea/engines/${engineId}/conclusions`,
  newConclusion: (engineId: string = ':engineId') =>
    `/ea/engines/${engineId}/conclusions/new`,
  viewConclusion: (
    engineId: string = ':engineId',
    conclusionId: string = ':conclusionId'
  ) => `/ea/engines/${engineId}/conclusions/${conclusionId}`,
};

export type EngineParams = {
  engineId: string;
};

export type AnswerParams = {
  answerId: string;
};

export type QuestionParams = {
  questionId: string;
};

export type ConclusionParams = {
  conclusionId: string;
};

export default paths;
