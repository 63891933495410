import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Answer, currentJourneyNode, Journey } from '../../api/model/Journey';
import JourneyService from '../../api/service/JourneyService';
import useService from '../../hooks/UseService';
import SessionContext from '../../store/SessionContext';
import { ApiError, makeHttpRequest } from '../../util/http';
import { newCacheBuster } from '../../util/math';
import paths from '../../util/paths';
import Box from '../box/Box';
import ConclusionView from './ConclusionView';
import styles from './JourneyView.module.css';
import QuestionView from './QuestionView';

const EmptyJourneyView = () => {
  const theHistory = useHistory();

  theHistory.replace(paths.listSessions); // or push

  return (
    <Box>
      <div className={styles.JourneyView}>
        <div>Please select a session</div>
      </div>
    </Box>
  );
};

const LoadingJourneyView = () => (
  <Box>
    <div className={styles.JourneyView}>
      <div>Loading...</div>
    </div>
  </Box>
);

interface ErrorJourneyViewProps {
  error: ApiError;
}

const ErrorJourneyView = ({ error }: ErrorJourneyViewProps) => (
  <Box>
    <div className={styles.JourneyView}>
      <div>Encountered unexpected data</div>
      <p className="error">{error.message}</p>
    </div>
  </Box>
);

const JourneyView = () => {
  const sessionCtx = useContext(SessionContext);
  const { activeSession } = sessionCtx;
  if (!activeSession) {
    return <EmptyJourneyView />;
  }
  const { sessionId } = activeSession;

  const [config] = useState(JourneyService.getJourney(sessionId));
  const [journeyCacheBuster] = useState(newCacheBuster());

  const {
    data: initialJourney,
    error,
    loading,
  } = useService<Journey>({
    config,
    cacheBuster: journeyCacheBuster,
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState<Journey>();
  const [submitError, setSubmitError] = useState<ApiError>();

  function onSelectAnswer(answer: Answer) {
    const callConfig = JourneyService.updateJourney(sessionId, answer.answerId);

    makeHttpRequest<Journey>(
      callConfig,
      setSubmitLoading,
      setSubmitResponse,
      setSubmitError
    );
  }

  function onSelectBack() {
    const callConfig = JourneyService.updateJourney(sessionId, 'BACK');

    makeHttpRequest<Journey>(
      callConfig,
      setSubmitLoading,
      setSubmitResponse,
      setSubmitError
    );
  }

  useEffect(() => {
    if (!submitLoading && (submitResponse || submitError)) {
      // setJourneyCacheBuster(newCacheBuster());
    }
  }, [submitLoading, submitResponse, submitError]);

  if (loading) {
    return <LoadingJourneyView />;
  }

  if (error) {
    return <ErrorJourneyView error={error} />;
  }

  function clientSideErrorJourneyView(cause: string) {
    const journeyError = { message: `An unexpected error occurred: ${cause}` };
    return <ErrorJourneyView error={journeyError} />;
  }

  const journey = submitResponse || initialJourney;

  if (!journey) {
    return clientSideErrorJourneyView('No journey');
  }

  const node = currentJourneyNode(journey);
  // console.log(journey);

  if (node.isConclusion) {
    const { conclusion } = node;
    if (!conclusion) {
      return clientSideErrorJourneyView('No conclusion');
    }
    return (
      <ConclusionView conclusion={conclusion} onSelectBack={onSelectBack} />
    );
  }
  const { question } = node;
  if (!question) {
    return clientSideErrorJourneyView('No question');
  }
  return (
    <QuestionView
      question={question}
      onSelectAnswer={onSelectAnswer}
      onSelectBack={onSelectBack}
    />
  );
};

export default JourneyView;
