import React from 'react';

import Modal from '../modal/Modal';
import SignInForm from './SignInForm';

interface SignInModalProps {
  closeHandler: () => void;
}
const SignInModal = ({ closeHandler }: SignInModalProps) => (
  <Modal
    modalContent={<SignInForm />}
    modalActions={[
      {
        actionText: 'Back',
        actionIsPrimary: false,
        actionHandler: closeHandler,
      },
    ]}
  />
);

export { SignInModal as default };
