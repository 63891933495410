import { apiCallConfig, ApiCallConfig } from '../../../util/http';
import { NewEngineRequest } from '../../model/admin/Request';
import { Engine } from '../../model/Journey';

class EngineAdminService {
  static newEngine(newEngineRequest: NewEngineRequest): ApiCallConfig<Engine> {
    return apiCallConfig('/engine-admin/engines', 'post', newEngineRequest);
  }

  static listEngines(): ApiCallConfig<Engine[]> {
    return apiCallConfig('/engine-admin/engines');
  }

  static getEngineById(engineId: string): ApiCallConfig<Engine> {
    return apiCallConfig(`/engine-admin/engines/${engineId}`);
  }
}

export default EngineAdminService;
