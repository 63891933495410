import React from 'react';

import { Button } from '@mui/material';

import { Conclusion } from '../../api/model/Journey';
import Box from '../box/Box';
import styles from './JourneyView.module.css';

interface ConclusionViewProps {
  conclusion: Conclusion;
  onSelectBack: () => void;
}
const ConclusionView = ({ conclusion, onSelectBack }: ConclusionViewProps) => (
  <Box>
    <span className={styles.Intro}>{conclusion.introText}</span>
    <span className={styles.Conclusion}>{conclusion.conclusionText}</span>
    {conclusion.conclusionImage && (
      <img
        src={conclusion.conclusionImage.imageUrl}
        className={styles.nodeImg}
        alt="Supporting conclusion illustration"
      />
    )}
    <Button onClick={onSelectBack}>Back</Button>
  </Box>
);

export default ConclusionView;
