import React, { FormEvent, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Session } from '../../api/model/Session';
import SessionService from '../../api/service/SessionService';
import useTextInput from '../../hooks/UseTextInput';
import { inputStyles } from '../../util/forms';
import { ApiError, makeHttpRequest } from '../../util/http';
import { notBlank } from '../../util/validation';
import Card from '../card/Card';
import ImageInput from '../imageinput/ImageInput';
import Spinner from '../spinner/Spinner';
import styles from './NewSessionForm.module.scss';

interface NewSessionFormProps {
  onCreateNewSession: () => void;
  /** Leave as null to reset the form instead of navigating away on submit. */
  navigateOnSubmit: string | null;
}

const NewSessionForm = ({
  onCreateNewSession,
  navigateOnSubmit,
}: NewSessionFormProps) => {
  const {
    value: sessionNameValue,
    isValid: sessionNameIsValid,
    hasError: sessionNameHasError,
    valueChangeHandler: sessionNameChangeHandler,
    inputBlurHandler: sessionNameBlurHandler,
    reset: sessionNameReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter a valid session name',
  });

  const formIsValid = sessionNameIsValid;

  const theHistory = useHistory();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Session>();
  const [error, setError] = useState<ApiError>();

  const formDisabled = loading;
  const formError = error;

  // Success handler
  useEffect(() => {
    if (!response) return;

    // Notify success handler, then either navigate away or reset the form
    onCreateNewSession();
    if (navigateOnSubmit) {
      theHistory.replace(navigateOnSubmit); // or push
    } else {
      sessionNameReset();
    }
  }, [response]);

  function handleCreateNewSession() {
    const callConfig = SessionService.newSession(sessionNameValue);

    makeHttpRequest<Session>(callConfig, setLoading, setResponse, setError);
  }

  const formSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    handleCreateNewSession();
  };

  const styleC = styles.control;
  const styleE = styles.error;

  return (
    <Card>
      <form className={styles.form} onSubmit={formSubmitHandler}>
        <h1>New session</h1>
        <div className={inputStyles(sessionNameHasError, styleC, styleE)}>
          <label htmlFor="session_name">
            Session Name
            <input
              type="text"
              id="session_name"
              onChange={sessionNameChangeHandler}
              onBlur={sessionNameBlurHandler}
              value={sessionNameValue}
              disabled={formDisabled}
            />
            {sessionNameHasError && (
              <p className={styles.errorText}>
                Please enter a valid session name
              </p>
            )}
          </label>
        </div>
        <ImageInput />
        <div className={styles.actions}>
          <button
            className={styles.btn}
            type="submit"
            disabled={!formIsValid || formDisabled}
          >
            Start Session
          </button>
          {loading && <Spinner />}
          {formError && <p className={styles.errorText}>{formError.message}</p>}
        </div>
      </form>
    </Card>
  );
};

export default NewSessionForm;
