export function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function printIsDev() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Running in dev mode');
  } else {
    // eslint-disable-next-line no-console
    console.log('Running in production mode');
  }
}
