import { useState, useEffect } from 'react';

import { ApiCallConfig, ApiError, makeHttpRequest } from '../util/http';

// The caller should persist these input values with useState to avoid
// incurring infinite reruns
type UseServiceProps<T> = {
  config: ApiCallConfig<T>;
  cacheBuster?: number;
};

// https://stackoverflow.com/questions/65297090/how-do-i-build-a-generic-typescript-custom-hook
interface ReturnedData<R, E> {
  data: R | undefined;
  error: E | undefined;
  loading: boolean;
}

function useService<T>({
  config,
  cacheBuster,
}: UseServiceProps<T>): ReturnedData<T, ApiError> {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<T>();
  const [error, setError] = useState<ApiError>();

  useEffect(() => {
    const { cancel } = makeHttpRequest<T>(
      config,
      setLoading,
      setResponse,
      setError
    );
    return cancel;
  }, [config, cacheBuster]);

  // Return values for caller of custom hook
  return { data: response, error, loading };
}

export default useService;
