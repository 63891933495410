import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Question } from '../../../api/model/Journey';
import QuestionAdminService from '../../../api/service/admin/QuestionAdminService';
import useService from '../../../hooks/UseService';
import paths from '../../../util/paths';
import styles from './QuestionList.module.css';

type QuestionListProps = {
  engineId: string;
  cacheBuster: number;
};

const QuestionList = ({ engineId, cacheBuster }: QuestionListProps) => {
  const [config] = useState(
    QuestionAdminService.listQuestionsForEngine(engineId)
  );

  const { data, error, loading } = useService<Question[]>({
    config,
    cacheBuster,
  });
  const questionList = data;

  if (loading) {
    return (
      <div className={styles.QuestionList}>
        <p>Loading questions...</p>
      </div>
    );
  }
  if (error || questionList === undefined) {
    return (
      <div className={styles.QuestionList}>
        <p className="error">Error: {error?.message}</p>
      </div>
    ); // TODO(robert): Generalise error styling
  }
  return (
    <div className={styles.QuestionList}>
      {questionList.map((question) => (
        <p key={question.questionId}>
          <Link to={paths.viewQuestion(engineId, question.questionId)}>
            {question.questionId}, {question.introText}, {question.questionText}
            ,{question.questionImage?.imageUrl}, {question.answers.toString()},
            {question.precacheImages.toString()}
          </Link>
        </p>
      ))}
      {questionList.length === 0 && <p>No questions</p>}
    </div>
  );
};

export default QuestionList;
