import React, { useContext } from 'react';

import { BrowserRouter } from 'react-router-dom';

import MediaPrecacheContext from '../../store/MediaPrecacheContext';
import {
  ensureInitBackgrounds,
  initialBackgrounds,
} from '../../util/background';
import { isDev, printIsDev } from '../../util/env';
import Main from '../main/Main';
import SplashScreen from '../splash/SplashScreen';

/* The AppCanvas sets up routing for the Main layout, and handles showing the splash screen first. */
const AppCanvas = () => {
  const mediaPrecacheCtx = useContext(MediaPrecacheContext);
  printIsDev();
  return (
    <SplashScreen
      splashTime={isDev() ? 1000 : 12000}
      backgroundAction={() => {
        // TODO(robert): Force Firebase user init if necessary
        ensureInitBackgrounds();
        const bgInit = initialBackgrounds();
        bgInit.forEach((bgImg) => {
          if (!mediaPrecacheCtx.hasImage(bgImg)) {
            mediaPrecacheCtx.addImage(bgImg);
          }
        });
      }}
    >
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </SplashScreen>
  );
};

export default AppCanvas;
