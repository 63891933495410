import React, { FormEvent, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';

import { Conclusion } from '../../../api/model/Journey';
import ConclusionAdminService from '../../../api/service/admin/ConclusionAdminService';
import useService from '../../../hooks/UseService';
import useTextInput from '../../../hooks/UseTextInput';
import { inputStyles } from '../../../util/forms';
import { ApiError, makeHttpRequest, noApiCall } from '../../../util/http';
import { anyValid, notBlank } from '../../../util/validation';
import Card from '../../card/Card';
import Spinner from '../../spinner/Spinner';
import styles from './NewConclusionForm.module.scss';

interface NewConclusionFormProps {
  engineId: string;
  conclusionId: string | null;
  onCreateNewConclusion: () => void;
  onEditExistingConclusion: () => void;
  /** Leave as null to reset the form instead of navigating away on submit. */
  navigateOnSubmit: string | null;
}

const NewConclusionForm = ({
  engineId,
  conclusionId,
  onCreateNewConclusion,
  onEditExistingConclusion,
  navigateOnSubmit,
}: NewConclusionFormProps) => {
  const {
    value: introTextValue,
    isValid: introTextIsValid,
    hasError: introTextHasError,
    valueChangeHandler: introTextChangeHandler,
    inputBlurHandler: introTextBlurHandler,
    reset: introTextReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter valid intro text',
  });

  const {
    value: conclusionTextValue,
    isValid: conclusionTextIsValid,
    hasError: conclusionTextHasError,
    errorMessage: conclusionTextErrorMessage,
    valueChangeHandler: conclusionTextChangeHandler,
    inputBlurHandler: conclusionTextBlurHandler,
    reset: conclusionTextReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter valid conclusion text',
  });

  const {
    value: imageValue,
    isValid: imageIsValid,
    hasError: imageHasError,
    valueChangeHandler: imageChangeHandler,
    inputBlurHandler: imageBlurHandler,
    reset: imageReset,
  } = useTextInput({
    validateValue: anyValid,
    defaultErrorPrompt: 'Please enter a valid image URL',
  });

  const formIsValid = introTextIsValid && conclusionTextIsValid && imageIsValid;

  const theHistory = useHistory();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState<Conclusion>();
  const [submitError, setSubmitError] = useState<ApiError>();

  // Existing data loader
  const [config] = useState(
    conclusionId !== null
      ? ConclusionAdminService.getConclusionById(engineId, conclusionId)
      : noApiCall()
  );
  const {
    data: existingResponse,
    error: existingError,
    loading: existingLoading,
  } = useService<Conclusion>({ config });

  const formDisabled = existingLoading || submitLoading;
  const formError = existingError || submitError;

  // Existing data success handler
  useEffect(() => {
    if (!existingResponse) return;

    introTextReset(existingResponse.introText);
    conclusionTextReset(existingResponse.conclusionText);
    imageReset(existingResponse.conclusionImage?.imageUrl);
  }, [existingResponse]);

  // Submit success handler
  useEffect(() => {
    if (!submitResponse) return;

    // Notify success handler, then either navigate away or reset the form
    if (conclusionId !== null) {
      onEditExistingConclusion();
    } else {
      onCreateNewConclusion();
    }
    if (navigateOnSubmit) {
      theHistory.replace(navigateOnSubmit); // or push
    } else {
      introTextReset();
      conclusionTextReset();
      imageReset();
    }
  }, [submitResponse]);

  function handleSubmitConclusion() {
    const image = imageValue ? { imageUrl: imageValue } : null;
    const conclusionRequest = {
      introText: introTextValue,
      conclusionText: conclusionTextValue,
      conclusionImage: image,
    };
    const callConfig =
      conclusionId !== null
        ? ConclusionAdminService.updateConclusionById(
            engineId,
            conclusionId,
            conclusionRequest
          )
        : ConclusionAdminService.newConclusion(engineId, conclusionRequest);

    makeHttpRequest<Conclusion>(
      callConfig,
      setSubmitLoading,
      setSubmitResponse,
      setSubmitError
    );
  }

  const formSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    handleSubmitConclusion();
  };

  const styleC = styles.control;
  const styleE = styles.error;

  return (
    <Card>
      <form className={styles.form} onSubmit={formSubmitHandler}>
        <h1>New conclusion</h1>
        <div className={inputStyles(introTextHasError, styleC, styleE)}>
          <label htmlFor="intro_text">
            Intro text
            <input
              type="text"
              id="intro_text"
              onChange={introTextChangeHandler}
              onBlur={introTextBlurHandler}
              value={introTextValue}
              disabled={formDisabled}
            />
            {introTextHasError && (
              <p className={styles.errorText}>Please enter valid intro text</p>
            )}
          </label>
        </div>
        <TextField
          id="conclusion_text"
          label="Conclusion text"
          variant="outlined"
          fullWidth
          multiline
          minRows={5}
          margin="normal"
          value={conclusionTextValue}
          onChange={conclusionTextChangeHandler}
          onBlur={conclusionTextBlurHandler}
          error={conclusionTextHasError}
          helperText={conclusionTextErrorMessage}
          disabled={formDisabled}
        />
        <div className={inputStyles(imageHasError, styleC, styleE)}>
          <label htmlFor="image_url">
            Image URL
            <input
              type="text"
              id="image_url"
              onChange={imageChangeHandler}
              onBlur={imageBlurHandler}
              value={imageValue}
              disabled={formDisabled}
            />
            {imageHasError && (
              <p className={styles.errorText}>Please enter a valid image URL</p>
            )}
          </label>
        </div>
        <div className={styles.actions}>
          <button
            className={styles.btn}
            type="submit"
            disabled={!formIsValid || formDisabled}
          >
            {conclusionId !== null ? 'Update Conclusion' : 'Create Conclusion'}
          </button>
          {submitLoading && <Spinner />}
          {formError && <p className={styles.errorText}>{formError.message}</p>}
        </div>
      </form>
    </Card>
  );
};

export default NewConclusionForm;
