import * as React from 'react';
import { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { Question } from '../../../api/model/Journey';
import QuestionAdminService from '../../../api/service/admin/QuestionAdminService';
import useService from '../../../hooks/UseService';
import { newCacheBuster } from '../../../util/math';

interface QuestionDropdownProps {
  fieldLabel: string;
  engineId: string;
  selectedQuestion: Question | null;
  onChangeQuestion: (question: Question | null) => void;
  disabled: boolean;
  refreshEveryOpen: boolean;
}

export default function QuestionDropdown({
  fieldLabel,
  engineId,
  selectedQuestion,
  onChangeQuestion,
  disabled,
  refreshEveryOpen,
}: QuestionDropdownProps) {
  const [config] = useState(
    QuestionAdminService.listQuestionsForEngine(engineId)
  );
  const [cb, setCb] = useState(newCacheBuster());
  const updateCb = () => {
    if (refreshEveryOpen) {
      setCb(newCacheBuster());
    }
  };

  const { data, loading } = useService<Question[]>({
    config,
    cacheBuster: cb,
  });
  const questionList = data || [];

  const setValue = (event: React.SyntheticEvent, value: Question | null) => {
    onChangeQuestion(value);
  };

  return (
    <Autocomplete
      id="selected_question"
      disablePortal
      sx={{ mt: 1 }}
      value={selectedQuestion}
      onChange={setValue}
      onOpen={updateCb}
      disabled={disabled}
      isOptionEqualToValue={(option, value) =>
        option.questionId === value.questionId
      }
      getOptionLabel={(option) =>
        `${option.questionId}: ${option.questionText.substring(0, 50)}`
      }
      options={questionList}
      loading={loading}
      renderInput={(params) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          label={fieldLabel}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
