import * as React from 'react';
import { useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { Answer } from '../../../api/model/Journey';
import AnswerAdminService from '../../../api/service/admin/AnswerAdminService';
import useService from '../../../hooks/UseService';
import { newCacheBuster } from '../../../util/math';

interface AnswerDropdownProps {
  fieldLabel: string;
  engineId: string;
  selectedAnswers: Answer[];
  onChangeAnswers: (answers: Answer[]) => void;
  disabled: boolean;
  refreshEveryOpen: boolean;
}

export default function AnswerDropdown({
  fieldLabel,
  engineId,
  selectedAnswers,
  onChangeAnswers,
  disabled,
  refreshEveryOpen,
}: AnswerDropdownProps) {
  const [config] = useState(AnswerAdminService.listAnswersForEngine(engineId));
  const [cb, setCb] = useState(newCacheBuster());
  const updateCb = () => {
    if (refreshEveryOpen) {
      setCb(newCacheBuster());
    }
  };

  const { data, loading } = useService<Answer[]>({
    config,
    cacheBuster: cb,
  });
  const answerList = data || [];

  const setValues = (event: React.SyntheticEvent, value: Answer[]) => {
    onChangeAnswers(value);
  };

  return (
    <Autocomplete
      id="selected_answers"
      multiple
      filterSelectedOptions
      disablePortal
      sx={{ mt: 1 }}
      value={selectedAnswers}
      onChange={setValues}
      onOpen={updateCb}
      disabled={disabled}
      isOptionEqualToValue={(option, value) =>
        option.answerId === value.answerId
      }
      getOptionLabel={(option) =>
        `${option.answerId}: ${option.text.substring(0, 50)}`
      }
      options={answerList}
      loading={loading}
      renderInput={(params) => (
        <TextField
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...params}
          label={fieldLabel}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
