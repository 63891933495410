import React from 'react';

import { Link } from 'react-router-dom';

import NewEngineForm from '../../components/admin/newengineform/NewEngineForm';
import Box from '../../components/box/Box';
import paths from '../../util/paths';
import styles from './AdminPages.module.scss';

const NewEnginePage = () => {
  function handleCreateNewEngine() {
    // console.log('New engine created');
  }

  return (
    <>
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
        </p>
        <p>
          You are about to create a new engine for photographic identification.
          Give your engine a name and a unique URL-safe ID to start.
        </p>
      </Box>
      <NewEngineForm
        onCreateNewEngine={handleCreateNewEngine}
        navigateOnSubmit={paths.listEngines}
      />
    </>
  );
};

export { NewEnginePage as default };
