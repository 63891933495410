import React from 'react';

import { Link, useParams } from 'react-router-dom';

import NewConclusionForm from '../../components/admin/newconclusionform/NewConclusionForm';
import Box from '../../components/box/Box';
import paths, { ConclusionParams, EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const ViewConclusionPage = () => {
  const { engineId } = useParams<EngineParams>();
  const { conclusionId } = useParams<ConclusionParams>();

  function handleCreateNewConclusion() {
    // console.log('New conclusion created');
  }

  function handleEditExistingConclusion() {
    // console.log('Existing conclusion edited');
  }

  return (
    <>
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
          <Link to={paths.listConclusions(engineId)}>Conclusions</Link> /&nbsp;
        </p>
        <p>You are about to edit an existing conclusion.</p>
      </Box>
      <NewConclusionForm
        engineId={engineId}
        conclusionId={conclusionId}
        onCreateNewConclusion={handleCreateNewConclusion}
        onEditExistingConclusion={handleEditExistingConclusion}
        navigateOnSubmit={paths.listConclusions(engineId)}
      />
    </>
  );
};

export { ViewConclusionPage as default };
