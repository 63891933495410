import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Conclusion } from '../../../api/model/Journey';
import ConclusionAdminService from '../../../api/service/admin/ConclusionAdminService';
import useService from '../../../hooks/UseService';
import paths from '../../../util/paths';
import styles from './ConclusionList.module.css';

type ConclusionListProps = {
  engineId: string;
  cacheBuster: number;
};

const ConclusionList = ({ engineId, cacheBuster }: ConclusionListProps) => {
  const [config] = useState(
    ConclusionAdminService.listConclusionsForEngine(engineId)
  );

  const { data, error, loading } = useService<Conclusion[]>({
    config,
    cacheBuster,
  });
  const conclusionList = data;

  if (loading) {
    return (
      <div className={styles.ConclusionList}>
        <p>Loading conclusions...</p>
      </div>
    );
  }
  if (error || conclusionList === undefined) {
    return (
      <div className={styles.ConclusionList}>
        <p className="error">Error: {error?.message}</p>
      </div>
    ); // TODO(robert): Generalise error styling
  }
  return (
    <div className={styles.ConclusionList}>
      {conclusionList.map((conclusion) => (
        <p key={conclusion.conclusionId}>
          <Link to={paths.viewConclusion(engineId, conclusion.conclusionId)}>
            {conclusion.conclusionId}, {conclusion.introText},
            {conclusion.conclusionText}, {conclusion.conclusionImage?.imageUrl}
          </Link>
        </p>
      ))}
      {conclusionList.length === 0 && <p>No conclusions</p>}
    </div>
  );
};

export default ConclusionList;
