import React from 'react';

import Box from '../components/box/Box';
import NewSessionForm from '../components/newsessionform/NewSessionForm';
import imgNewSessionExample from '../res/new-session-example.jpg';
import paths from '../util/paths';
import styles from './NewSessionPage.module.css';

const NewSessionPage = () => {
  function handleCreateNewSession() {
    // console.log('New session created');
  }

  return (
    <>
      <Box>
        <p>
          You are about to identify a photographic object. Name your session to
          start.
        </p>
        <img
          src={imgNewSessionExample}
          alt="Example photographic identifications"
          className={styles.newSessionExample}
        />
      </Box>
      <NewSessionForm
        onCreateNewSession={handleCreateNewSession}
        navigateOnSubmit={paths.listSessions}
      />
    </>
  );
};

export { NewSessionPage as default };
