import React from 'react';

import { Link } from 'react-router-dom';

import Box from '../components/box/Box';
import SessionList from '../components/sessionlist/SessionList';
import paths from '../util/paths';

const AllSessionsPage = () => (
  <Box>
    <h1>Your Sessions</h1>
    <SessionList />
    <p />
    <p />
    <Link to={paths.newSession}>New session</Link>
  </Box>
);

export { AllSessionsPage as default };
