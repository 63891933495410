import { Image } from './Util';

/** A photographic identification engine. */
export interface Engine {
  /** The url-safe engine unique ID. */
  engineId: string;
  /** The engine display name. */
  engineName: string;
}

/** A presentation style for an answer. */
export enum AnswerStyle {
  TEXT_ONLY = 'TEXT_ONLY',
  TEXT_AND_ICON = 'TEXT_AND_ICON',
  TEXT_AND_IMAGE = 'TEXT_AND_IMAGE',
}

/** An answer option to let the user select. */
export interface Answer {
  /** The unique ID of the answer. */
  answerId: string;
  /** The presentation style of the answer. */
  presentationStyle: AnswerStyle;
  /** The main answer text. */
  text: string;
  /** The answer icon, if applicable. */
  icon: Image | null;
  /** The answer image, if applicable. */
  image: Image | null;
  /** The ID of the question this answer leads to, if applicable. */
  nextQuestionId: string | null;
  /** The ID of the conclusion this answer leads to, if applicable. */
  nextConclusionId: string | null;
}

/** A question to present the user. */
export interface Question {
  /** The unique ID of the question. */
  questionId: string;
  /** The introductory text. */
  introText: string;
  /** The main question text. */
  questionText: string;
  /** The question image, if applicable. */
  questionImage: Image | null;
  /** The list of possible answers to this question. */
  answers: Answer[];
  /** A list of images which the client may want to precache at this time. */
  precacheImages: Image[];
}

/** A conclusion to present the user. */
export interface Conclusion {
  /** The unique ID of the conclusion. */
  conclusionId: string;
  /** The introductory text. */
  introText: string;
  /** The main conclusion text. */
  conclusionText: string;
  /** The conclusion image, if applicable. */
  conclusionImage: Image | null;
}

/** A users journey through an engine graph. */
export interface Journey {
  /** The unique ID of the engine for this journey. */
  engineId: string;
  /** The node that this journey commences with. */
  startNode: JourneyNode;
  /** The list of edges travelled on this journey. */
  journeyPath: JourneyEdge[];
}

/** A node in the users journey graph. */
export interface JourneyNode {
  /** true if this node marks a conclusion of the journey. */
  isConclusion: boolean;
  /** The question ID, or conclusion ID, as applicable. */
  nodeId: string;
  /** The question, if this node is a question. */
  question: Question | null;
  /** The conclusion, if this node is a conclusion. */
  conclusion: Conclusion | null;
}

/** An edge in the users journey graph. */
export interface JourneyEdge {
  /** The answer selected, if this edge was from answering a question. */
  answer: Answer | null;
  /** true if this journey was from navigating backwards. */
  isBack: boolean;
  /** The destination this edge led to. */
  destination: JourneyNode;
}

// TODO(robert): Remove this old model

/** @deprecated A point in the users journey. */
export interface JourneyPoint {
  /** The question, if this journey is still incomplete. */
  question: Question | null;
  /** The conclusion, if this journey is now complete. */
  conclusion: Conclusion | null;
}

export const currentJourneyNode = (journey: Journey) => {
  const path = journey.journeyPath;
  return path.length > 0
    ? path[path.length - 1].destination
    : journey.startNode;
};
