import React, { useContext, useState } from 'react';

import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Session } from '../../api/model/Session';
import SessionService from '../../api/service/SessionService';
import useService from '../../hooks/UseService';
import SessionContext from '../../store/SessionContext';
import styles from './SessionList.module.css';

const SessionList = () => {
  const [config] = useState(SessionService.listSessions());
  const { data, error, loading } = useService<Session[]>({
    config,
  });
  const sessionList = data;

  const sessionCtx = useContext(SessionContext);
  const theHistory = useHistory();

  function selectSession(session: Session) {
    sessionCtx.setActiveSession(session);
    theHistory.push('/'); // TODO(robert): Use paths.ts
  }

  if (loading) {
    return (
      <div className={styles.SessionList}>
        <p>Loading sessions...</p>
      </div>
    );
  }
  if (error || sessionList === undefined) {
    return (
      <div className={styles.SessionList}>
        <p className="error">Error: {error?.message}</p>
      </div>
    ); // TODO(robert): Generalise error styling
  }
  return (
    <div className={styles.SessionList}>
      {sessionList.map((session) => (
        <div key={session.sessionId}>
          <Button
            type="button"
            onClick={() => {
              selectSession(session);
            }}
          >
            {session.sessionName}
          </Button>
        </div>
      ))}
      {sessionList.length === 0 && <p>No sessions</p>}
    </div>
  );
};

export default SessionList;
