import React, { ReactFragment } from 'react';

import styles from './Modal.module.scss';
import ModalAction, { ModalActionProps } from './ModalAction';

interface ModalProps {
  modalContent: ReactFragment;
  modalActions: ModalActionProps[];
}
const Modal = ({ modalContent, modalActions }: ModalProps) => (
  <div>
    <div className={styles.modal}>
      {modalContent}
      <div className={styles.actions}>
        {modalActions.map((actionProps) => (
          <ModalAction
            key={actionProps.actionText}
            actionText={actionProps.actionText}
            actionIsPrimary={actionProps.actionIsPrimary}
            actionHandler={actionProps.actionHandler}
          />
        ))}
      </div>
    </div>
    <div className={styles.backdrop} />
  </div>
);
export default Modal;
