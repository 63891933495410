import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { Answer } from '../../../api/model/Journey';
import AnswerAdminService from '../../../api/service/admin/AnswerAdminService';
import useService from '../../../hooks/UseService';
import paths from '../../../util/paths';
import styles from './AnswerList.module.css';

type AnswerListProps = {
  engineId: string;
  cacheBuster: number;
};

const AnswerList = ({ engineId, cacheBuster }: AnswerListProps) => {
  const [config] = useState(AnswerAdminService.listAnswersForEngine(engineId));

  const { data, error, loading } = useService<Answer[]>({
    config,
    cacheBuster,
  });
  const answerList = data;

  if (loading) {
    return (
      <div className={styles.AnswerList}>
        <p>Loading answers...</p>
      </div>
    );
  }
  if (error || answerList === undefined) {
    return (
      <div className={styles.AnswerList}>
        <p className="error">Error: {error?.message}</p>
      </div>
    ); // TODO(robert): Generalise error styling
  }
  return (
    <div className={styles.AnswerList}>
      {answerList.map((answer) => (
        <p key={answer.answerId}>
          <Link to={paths.viewAnswer(engineId, answer.answerId)}>
            {answer.answerId}, {answer.text}, {answer.presentationStyle},
            {answer.icon?.imageUrl}, {answer.image?.imageUrl},
            {answer.nextQuestionId}, {answer.nextConclusionId}
          </Link>
        </p>
      ))}
      {answerList.length === 0 && <p>No answers</p>}
    </div>
  );
};

export default AnswerList;
