/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ReactNode, useEffect, useState } from 'react';

/*
interface Precache {
  arr: Promise<void>[];
  obj: { [key: string]: Promise<void> };
}

declare global {
  interface Window {
    idpPrecache: Precache;
  }
}

const cacheImages = async (imageUrls: string[]) => {
  window.idpPrecache = window.idpPrecache || { arr: [], obj: {} };
  imageUrls.forEach((imageUrl) => {
    if (window.idpPrecache.obj[imageUrl] == null) {
      const newCachingPromise = new Promise<void>((resolve, reject) => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => resolve();
        image.onerror = () => reject();
      });
      window.idpPrecache.arr.push(newCachingPromise);
      window.idpPrecache.obj[imageUrl] = newCachingPromise;
    }
  });
  await Promise.all(window.idpPrecache.arr);
};
*/

interface ImagePrecache {
  arr: HTMLImageElement[];
  obj: { [key: string]: boolean };
}

declare global {
  interface Window {
    idpIPrecache: ImagePrecache;
  }
}

const cacheImages = async (imageUrls: string[]) => {
  window.idpIPrecache = window.idpIPrecache || { arr: [], obj: {} };
  imageUrls.forEach((imageUrl) => {
    if (!window.idpIPrecache.obj[imageUrl]) {
      const image = new Image();
      image.src = imageUrl;
      window.idpIPrecache.arr.push(image);
      window.idpIPrecache.obj[imageUrl] = true;
    }
  });
};

const uncacheImages = async (imageUrls: string[]) => {
  window.idpIPrecache = window.idpIPrecache || { arr: [], obj: {} };
  imageUrls.forEach((imageUrl) => {
    if (window.idpIPrecache.obj[imageUrl]) {
      const removeIndex = window.idpIPrecache.arr.findIndex(
        (value) => value.src === imageUrl
      );
      window.idpIPrecache.arr.splice(removeIndex, 1);
      delete window.idpIPrecache.obj[imageUrl];
    }
  });
};

type MediaPrecacheCtx = {
  images: string[];
  count: number;
  addImage: (imageUrl: string) => void;
  removeImage: (imageUrl: string) => void;
  hasImage: (imageUrl: string) => boolean;
};

const MediaPrecacheContext = createContext({
  images: [] as string[],
  count: 0,
  addImage: (imageUrl: string) => {},
  removeImage: (imageUrl: string) => {},
  hasImage: (imageUrl: string) => {},
} as MediaPrecacheCtx);

interface MediaPrecacheContextProviderProps {
  children: ReactNode;
}
export const MediaPrecacheContextProvider = ({
  children,
}: MediaPrecacheContextProviderProps) => {
  const [images, setImages] = useState([] as string[]);
  const [clearedImages, setClearedImages] = useState([] as string[]);

  useEffect(() => {
    cacheImages(images);
  }, [images]);

  useEffect(() => {
    uncacheImages(clearedImages);
  }, [clearedImages]);

  function addImageHandler(imageUrl: string) {
    setImages((lastItems: string[]) => lastItems.concat(imageUrl));
  }

  function removeImageHandler(imageUrl: string) {
    setImages((lastItems: string[]) =>
      lastItems.filter((image) => image === imageUrl)
    );
    setClearedImages((lastItems: string[]) => lastItems.concat(imageUrl));
  }

  function hasImageHandler(imageUrl: string) {
    return images.some((image) => image === imageUrl);
  }

  const context = {
    images,
    count: images.length,
    addImage: addImageHandler,
    removeImage: removeImageHandler,
    hasImage: hasImageHandler,
  };
  return (
    <MediaPrecacheContext.Provider value={context}>
      {children}
    </MediaPrecacheContext.Provider>
  );
};

export default MediaPrecacheContext;
