import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useHistory } from 'react-router-dom';

import { getFirebaseUiConfig, getUsername } from '../../util/firebase';

function SignInForm() {
  const history = useHistory();
  const [userSignedIn, setUserSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        const signedIn = !!user && !user.isAnonymous;
        setUserSignedIn(signedIn);
        if (userSignedIn) {
          history.replace(`/`);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  });

  if (!userSignedIn) {
    return (
      <div>
        <h1>Sign In</h1>
        <p>Please sign-in to save and load your sessions.</p>
        <StyledFirebaseAuth
          uiConfig={getFirebaseUiConfig()}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }
  return (
    <div>
      <h1>Sign In</h1>
      <p>Welcome {getUsername()}!</p>
    </div>
  );
}

export default SignInForm;
