import React, { useContext, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import { Link, useHistory } from 'react-router-dom';

import loopLogo from '../../res/logo/logo-loop-forever.svg';
import SessionContext from '../../store/SessionContext';
import { signOut } from '../../util/firebase';
import paths from '../../util/paths';
import styles from './NavBar.module.css';

const NavBar = () => {
  const theHistory = useHistory();

  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  const sessionCtx = useContext(SessionContext);
  const { activeSession } = sessionCtx;
  const sessionName = activeSession?.sessionName || '';

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user && !user.isAnonymous);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  function handleSignIn() {
    theHistory.replace(paths.signIn);
  }

  function handleSignOut() {
    signOut();
  }

  return (
    <nav className={styles.MainNav}>
      <img src={loopLogo} className={styles.navLogo} alt="Logo" />
      <div className={styles.sessionHeader}>
        <h1 className={styles.navSession}>{sessionName}</h1>
        <Link to={paths.listSessions} className={styles.navSessionList}>
          All sessions
        </Link>
      </div>
      {!isSignedIn && (
        <button
          type="button"
          className={styles.navButton}
          onClick={handleSignIn}
        >
          Sign in
        </button>
      )}
      {isSignedIn && (
        <button
          type="button"
          className={styles.navButton}
          onClick={handleSignOut}
        >
          Sign out
        </button>
      )}
    </nav>
  );
};
export default NavBar;
