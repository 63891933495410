import React, { FormEvent, useEffect, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';

import { Answer, Question } from '../../../api/model/Journey';
import QuestionAdminService from '../../../api/service/admin/QuestionAdminService';
import useService from '../../../hooks/UseService';
import useTextInput from '../../../hooks/UseTextInput';
import { ApiError, makeHttpRequest, noApiCall } from '../../../util/http';
import { anyValid, notBlank } from '../../../util/validation';
import Card from '../../card/Card';
import AnswerDropdown from './AnswerDropdown';
import styles from './NewQuestionForm.module.scss';

interface NewQuestionFormProps {
  engineId: string;
  questionId: string | null;
  onCreateNewQuestion: () => void;
  onEditExistingQuestion: () => void;
  /** Leave as null to reset the form instead of navigating away on submit. */
  navigateOnSubmit: string | null;
}

const NewQuestionForm = ({
  engineId,
  questionId,
  onCreateNewQuestion,
  onEditExistingQuestion,
  navigateOnSubmit,
}: NewQuestionFormProps) => {
  const {
    value: introTextValue,
    isValid: introTextIsValid,
    hasError: introTextHasError,
    errorMessage: introTextErrorMessage,
    valueChangeHandler: introTextChangeHandler,
    inputBlurHandler: introTextBlurHandler,
    reset: introTextReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter valid intro text',
  });

  const {
    value: questionTextValue,
    isValid: questionTextIsValid,
    hasError: questionTextHasError,
    errorMessage: questionTextErrorMessage,
    valueChangeHandler: questionTextChangeHandler,
    inputBlurHandler: questionTextBlurHandler,
    reset: questionTextReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter valid question text',
  });

  const {
    value: imageValue,
    isValid: imageIsValid,
    hasError: imageHasError,
    errorMessage: imageErrorMessage,
    valueChangeHandler: imageChangeHandler,
    inputBlurHandler: imageBlurHandler,
    reset: imageReset,
  } = useTextInput({
    validateValue: anyValid,
    defaultErrorPrompt: 'Please enter a valid image URL',
  });

  const [answers, setAnswers] = useState<Answer[]>([]);

  const formIsValid = introTextIsValid && questionTextIsValid && imageIsValid;

  const theHistory = useHistory();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitResponse, setSubmitResponse] = useState<Question>();
  const [submitError, setSubmitError] = useState<ApiError>();

  // Existing data loader
  const [config] = useState(
    questionId !== null
      ? QuestionAdminService.getQuestionById(engineId, questionId)
      : noApiCall()
  );
  const {
    data: existingResponse,
    error: existingError,
    loading: existingLoading,
  } = useService<Question>({ config });

  const formDisabled = existingLoading || submitLoading;
  const formError = existingError || submitError;

  // Existing data success handler
  useEffect(() => {
    if (!existingResponse) return;

    introTextReset(existingResponse.introText);
    questionTextReset(existingResponse.questionText);
    imageReset(existingResponse.questionImage?.imageUrl);
    setAnswers(existingResponse.answers);
  }, [existingResponse]);

  // Success handler
  useEffect(() => {
    if (!submitResponse) return;

    // Notify success handler, then either navigate away or reset the form
    if (questionId !== null) {
      onEditExistingQuestion();
    } else {
      onCreateNewQuestion();
    }
    if (navigateOnSubmit) {
      theHistory.replace(navigateOnSubmit); // or push
    } else {
      introTextReset();
      questionTextReset();
      imageReset();
      setAnswers([]);
    }
  }, [submitResponse]);

  function handleSubmitQuestion() {
    const image = imageValue ? { imageUrl: imageValue } : null;
    const answerIds = answers.map((answer) => answer.answerId);
    const questionRequest = {
      introText: introTextValue,
      questionText: questionTextValue,
      questionImage: image,
      answerIds,
      precacheImages: [],
    };
    const callConfig =
      questionId !== null
        ? QuestionAdminService.updateQuestionById(
            engineId,
            questionId,
            questionRequest
          )
        : QuestionAdminService.newQuestion(engineId, questionRequest);

    makeHttpRequest<Question>(
      callConfig,
      setSubmitLoading,
      setSubmitResponse,
      setSubmitError
    );
  }

  const formSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    handleSubmitQuestion();
  };

  return (
    <Card>
      <form className={styles.form} onSubmit={formSubmitHandler}>
        <h1>New question</h1>
        <TextField
          id="intro_text"
          label="Intro Text"
          variant="outlined"
          fullWidth
          margin="normal"
          value={introTextValue}
          onChange={introTextChangeHandler}
          onBlur={introTextBlurHandler}
          error={introTextHasError}
          helperText={introTextErrorMessage}
          disabled={formDisabled}
        />
        <TextField
          id="question_text"
          label="Question Text"
          variant="outlined"
          fullWidth
          margin="normal"
          value={questionTextValue}
          onChange={questionTextChangeHandler}
          onBlur={questionTextBlurHandler}
          error={questionTextHasError}
          helperText={questionTextErrorMessage}
          disabled={formDisabled}
        />
        <TextField
          id="image_url"
          label="Image URL"
          variant="outlined"
          fullWidth
          margin="normal"
          value={imageValue}
          onChange={imageChangeHandler}
          onBlur={imageBlurHandler}
          error={imageHasError}
          helperText={imageErrorMessage}
          disabled={formDisabled}
        />
        <AnswerDropdown
          fieldLabel="Answers"
          engineId={engineId}
          selectedAnswers={answers}
          onChangeAnswers={setAnswers}
          disabled={formDisabled}
          refreshEveryOpen={false}
        />
        <div className={styles.actions}>
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={!formIsValid || formDisabled}
            loading={submitLoading}
            loadingPosition="end"
            endIcon={<AddCircleIcon />}
          >
            {questionId !== null ? 'Update Question' : 'Create Question'}
          </LoadingButton>
          {formError && <p className={styles.errorText}>{formError.message}</p>}
        </div>
      </form>
    </Card>
  );
};

export default NewQuestionForm;
