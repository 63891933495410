import React from 'react';

import { Link, useParams } from 'react-router-dom';

import NewAnswerForm from '../../components/admin/newanswerform/NewAnswerForm';
import Box from '../../components/box/Box';
import paths, { EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const NewAnswerPage = () => {
  const { engineId } = useParams<EngineParams>();

  function handleCreateNewAnswer() {
    // console.log('New answer created');
  }

  return (
    <>
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
          <Link to={paths.listAnswers(engineId)}>Answers</Link> /&nbsp;
        </p>
        <p>You are about to create a new answer.</p>
      </Box>
      <NewAnswerForm
        engineId={engineId}
        answerId={null}
        onCreateNewAnswer={handleCreateNewAnswer}
        onEditExistingAnswer={() => {}}
        navigateOnSubmit={paths.listAnswers(engineId)}
      />
    </>
  );
};

export { NewAnswerPage as default };
