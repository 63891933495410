import React from 'react';

import { Link, useParams } from 'react-router-dom';

import NewConclusionForm from '../../components/admin/newconclusionform/NewConclusionForm';
import Box from '../../components/box/Box';
import paths, { EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const NewConclusionPage = () => {
  const { engineId } = useParams<EngineParams>();

  function handleCreateNewConclusion() {
    // console.log('New conclusion created');
  }

  return (
    <>
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
          <Link to={paths.listConclusions(engineId)}>Conclusions</Link> /&nbsp;
        </p>
        <p>You are about to create a new conclusion.</p>
      </Box>
      <NewConclusionForm
        engineId={engineId}
        conclusionId={null}
        onCreateNewConclusion={handleCreateNewConclusion}
        onEditExistingConclusion={() => {}}
        navigateOnSubmit={paths.listConclusions(engineId)}
      />
    </>
  );
};

export { NewConclusionPage as default };
