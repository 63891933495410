import { apiCallConfig, ApiCallConfig } from '../../util/http';
import { Session } from '../model/Session';

class SessionService {
  static newSession(sessionName: string): ApiCallConfig<Session> {
    return apiCallConfig('/sessions', 'post', { sessionName });
  }

  static listSessions(): ApiCallConfig<Session[]> {
    return apiCallConfig('/sessions');
  }
}

export default SessionService;
