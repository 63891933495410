import React from 'react';

import { Button } from '@mui/material';

import { Answer, Question } from '../../api/model/Journey';
import Box from '../box/Box';
import styles from './JourneyView.module.css';

interface QuestionViewProps {
  question: Question;
  onSelectAnswer: (answer: Answer) => void;
  onSelectBack: () => void;
}
const QuestionView = ({
  question,
  onSelectAnswer,
  onSelectBack,
}: QuestionViewProps) => (
  <Box>
    <span className={styles.Intro}>{question.introText}</span>
    <span className={styles.Question}>{question.questionText}</span>
    {question.questionImage && (
      <img
        src={question.questionImage.imageUrl}
        className={styles.nodeImg}
        alt="Supporting question illustration"
      />
    )}
    <div className={styles.Answers}>
      {question.answers.map((answer) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <a
          key={answer.answerId}
          onClick={() => {
            onSelectAnswer(answer);
          }}
        >
          {answer.image && (
            <img
              src={answer.image.imageUrl}
              className={styles.edgeImg}
              alt="Supporting answer illustration"
            />
          )}
          {answer.text}
        </a>
      ))}
    </div>
    <Button onClick={onSelectBack}>Back</Button>
  </Box>
);

export default QuestionView;
