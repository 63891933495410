import React, { useContext } from 'react';

import { MediaPrecacheContextProvider } from '../../store/MediaPrecacheContext';
import AppCanvas from './AppCanvas';

/** The App sets up contexts outside of the AppCanvas. */
const App = () => (
  <MediaPrecacheContextProvider>
    <AppCanvas />
  </MediaPrecacheContextProvider>
);

export default App;
