import { createTheme } from '@mui/material/styles';

const cpTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#42538A',
    },
    secondary: {
      main: '#FFC107',
    },
  },
});

export default cpTheme;
