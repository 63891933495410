import axios, { Method } from 'axios';

/** The HTTP client for use in the app. */
const http = axios.create({
  // PROD: 'https://api.id.photography/', DEV: 'http://localhost:8080/'
  baseURL: 'https://api.id.photography/',
  headers: {
    'Content-Type': 'application/json',
    common: {},
    post: {},
  },
});

/** The config information needed for each API call. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ApiCallConfig<T> = {
  url: string;
  method: Method;
  data: any;
  headers: any;
};

export type ApiError = {
  code?: string;
  message: string;
};

/** Convenience function for initialising API call config information. */
export function apiCallConfig<T>(
  url: string,
  method: Method = 'get',
  data: any = undefined,
  headers: any = undefined
): ApiCallConfig<T> {
  return {
    url,
    method,
    data,
    headers,
  };
}

/** Special short-circuit API call config. */
export function noApiCall<T>(): ApiCallConfig<T> {
  return apiCallConfig('');
}

export const getCancelTokenSource = () => {
  const cancelToken = axios.CancelToken;
  return cancelToken.source();
};

export const setAuthToken = (token: string | null) => {
  if (token === null) {
    delete http.defaults.headers.common.Authorization;
  } else {
    http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export type ApiRequest<T> = {
  config: ApiCallConfig<T>;
  cancel: () => void;
};

export const makeHttpRequest = <T>(
  config: ApiCallConfig<T>,
  setLoading: (isLoading: boolean) => void,
  setResponse: (data: T) => void,
  setError: (error: ApiError) => void
): ApiRequest<T> => {
  if (config.url === '') {
    setLoading(false);
    return { config, cancel: () => {} };
  }

  setLoading(true);
  const cts = getCancelTokenSource();

  const requestConfig = {
    ...config,
    cancelToken: cts.token,
  };

  http
    .request<T>(requestConfig)
    .then((res) => {
      setResponse(res.data);
    })
    .catch((err) => {
      // https://axios-http.com/docs/handling_errors
      const errResponse = err.response;
      const errRequest = err.request;

      if (errResponse) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const errStatus = errResponse.status;
        const errMessage =
          errStatus === 404
            ? 'Resource not found.'
            : 'An unexpected error has occurred.';
        setError({
          code: errStatus,
          message: errMessage,
        });
      } else if (errRequest) {
        // The request was made but no response was received
        setError({
          code: '0',
          message:
            'Your internet connection seems to be unstable. Please try again.',
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        setError({
          code: '-1',
          message: 'An unexpected error has occurred.',
        });
      }
    })
    .finally(() => {
      setLoading(false);
    });

  const cancel = () => {
    cts.cancel('Request aborted early');
  };

  return { config, cancel };
};

export default http;
