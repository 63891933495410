import { apiCallConfig, ApiCallConfig } from '../../../util/http';
import { NewConclusionRequest } from '../../model/admin/Request';
import { Conclusion } from '../../model/Journey';

class ConclusionAdminService {
  static listConclusionsForEngine(
    engineId: string
  ): ApiCallConfig<Conclusion[]> {
    return apiCallConfig(`/engine-admin/engines/${engineId}/conclusions`);
  }

  static newConclusion(
    engineId: string,
    newConclusionRequest: NewConclusionRequest
  ): ApiCallConfig<Conclusion> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/conclusions`,
      'post',
      newConclusionRequest
    );
  }

  static getConclusionById(
    engineId: string,
    conclusionId: string
  ): ApiCallConfig<Conclusion> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/conclusions/${conclusionId}`
    );
  }

  static updateConclusionById(
    engineId: string,
    conclusionId: string,
    newConclusionRequest: NewConclusionRequest
  ): ApiCallConfig<Conclusion> {
    return apiCallConfig(
      `/engine-admin/engines/${engineId}/conclusions/${conclusionId}`,
      'post',
      newConclusionRequest
    );
  }
}

export default ConclusionAdminService;
