import React from 'react';

import { Link } from 'react-router-dom';

import EngineList from '../../components/admin/enginelist/EngineList';
import Box from '../../components/box/Box';
import paths from '../../util/paths';
import styles from './AdminPages.module.scss';

const ListEnginesPage = () => (
  <Box>
    <p className={styles.breadcrumbs}>
      <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
    </p>
    <h1>Engines</h1>
    <p />
    <Link to={paths.newEngine} className={styles.btn} role="button">
      New engine
    </Link>
    <p />
    <EngineList />
    <p />
  </Box>
);

export { ListEnginesPage as default };
