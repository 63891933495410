import React, { useState } from 'react';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Camera from 'react-html5-camera-photo';

import styles from './ImageInput.module.css';
import ImagePreview from './ImagePreview';

import 'react-html5-camera-photo/build/css/index.css';

const ImageInput = () => {
  const [activated, setActivated] = useState(false);
  const [dataUri, setDataUri] = useState('');

  function handleTakePhotoAnimationDone(newDataUri: string) {
    // console.log('Photo taken');
    setDataUri(newDataUri);
  }
  const isFullscreen = false;

  if (!activated) {
    return (
      <div className={styles.ImageInputContainer}>
        <div className={styles.Hint}>
          Click to add an image of the
          <br />
          photographic object you are identifying
        </div>
        <PhotoCameraIcon
          className={styles.CameraIcon}
          onClick={() => {
            setActivated(true);
          }}
        />
        <div className={styles.Skip}>Skip</div>
      </div>
    );
  }
  return (
    <div className={styles.ImageInputContainer}>
      {dataUri ? (
        <ImagePreview dataUri={dataUri} isFullscreen={isFullscreen} />
      ) : (
        <div className={styles.ImgCamera}>
          <Camera
            onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
            isFullscreen={isFullscreen}
          />
        </div>
      )}
    </div>
  );
};

export default ImageInput;
