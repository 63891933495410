import React, { FormEvent, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Session } from '../../../api/model/Session';
import EngineAdminService from '../../../api/service/admin/EngineAdminService';
import useTextInput from '../../../hooks/UseTextInput';
import { inputStyles } from '../../../util/forms';
import { ApiError, makeHttpRequest } from '../../../util/http';
import { notBlank } from '../../../util/validation';
import Card from '../../card/Card';
import Spinner from '../../spinner/Spinner';
import styles from './NewEngineForm.module.scss';

interface NewEngineFormProps {
  onCreateNewEngine: () => void;
  /** Leave as null to reset the form instead of navigating away on submit. */
  navigateOnSubmit: string | null;
}
const NewEngineForm = ({
  onCreateNewEngine,
  navigateOnSubmit,
}: NewEngineFormProps) => {
  const {
    value: engineIdValue,
    isValid: engineIdIsValid,
    hasError: engineIdHasError,
    valueChangeHandler: engineIdChangeHandler,
    inputBlurHandler: engineIdBlurHandler,
    reset: engineIdReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter a valid engine ID',
  });

  const {
    value: engineNameValue,
    isValid: engineNameIsValid,
    hasError: engineNameHasError,
    valueChangeHandler: engineNameChangeHandler,
    inputBlurHandler: engineNameBlurHandler,
    reset: engineNameReset,
  } = useTextInput({
    validateValue: notBlank,
    defaultErrorPrompt: 'Please enter a valid engine name',
  });

  const formIsValid = engineIdIsValid && engineNameIsValid;

  const theHistory = useHistory();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Session>();
  const [error, setError] = useState<ApiError>();

  const formDisabled = loading;
  const formError = error;

  // Success handler
  useEffect(() => {
    if (!response) return;

    // Notify success handler, then either navigate away or reset the form
    onCreateNewEngine();
    if (navigateOnSubmit) {
      theHistory.replace(navigateOnSubmit); // or push
    } else {
      engineIdReset();
      engineNameReset();
    }
  }, [response]);

  function handleCreateNewEngine() {
    const callConfig = EngineAdminService.newEngine({
      engineId: engineIdValue,
      engineName: engineNameValue,
    });

    makeHttpRequest<Session>(callConfig, setLoading, setResponse, setError);
  }

  const formSubmitHandler = (event: FormEvent) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    handleCreateNewEngine();
  };

  const styleC = styles.control;
  const styleE = styles.error;

  return (
    <Card>
      <form className={styles.form} onSubmit={formSubmitHandler}>
        <h1>New engine</h1>
        <div className={inputStyles(engineIdHasError, styleC, styleE)}>
          <label htmlFor="engine_id">
            Engine ID
            <input
              type="text"
              id="engine_id"
              onChange={engineIdChangeHandler}
              onBlur={engineIdBlurHandler}
              value={engineIdValue}
              disabled={formDisabled}
            />
            {engineIdHasError && (
              <p className={styles.errorText}>Please enter a valid engine ID</p>
            )}
          </label>
        </div>
        <div className={inputStyles(engineNameHasError, styleC, styleE)}>
          <label htmlFor="engine_name">
            Engine Name
            <input
              type="text"
              id="engine_name"
              onChange={engineNameChangeHandler}
              onBlur={engineNameBlurHandler}
              value={engineNameValue}
              disabled={formDisabled}
            />
            {engineNameHasError && (
              <p className={styles.errorText}>
                Please enter a valid engine name
              </p>
            )}
          </label>
        </div>
        <div className={styles.actions}>
          <button
            className={styles.btn}
            type="submit"
            disabled={!formIsValid || formDisabled}
          >
            Create Engine
          </button>
          {loading && <Spinner />}
          {formError && <p className={styles.errorText}>{formError.message}</p>}
        </div>
      </form>
    </Card>
  );
};

export default NewEngineForm;
