import { ChangeEvent, useState } from 'react';

type UseTextInputProps = {
  initialValue?: string;
  validateValue: (value: string) => boolean;
  defaultErrorPrompt: string;
};

const useTextInput = ({
  initialValue,
  validateValue,
  defaultErrorPrompt,
}: UseTextInputProps) => {
  const [enteredValue, setEnteredValue] = useState(initialValue || '');
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = validateValue(enteredValue);
  const hasError = !valueIsValid && isTouched;
  const errorMessage = hasError ? defaultErrorPrompt : '';

  const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredValue(event.target.value);
  };

  const inputBlurHandler = () => {
    // event: FocusEvent<HTMLInputElement>
    setIsTouched(true);
  };

  const reset = (resetValue?: string) => {
    setEnteredValue(resetValue || '');
    setIsTouched(false);
  };

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    errorMessage,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useTextInput;
