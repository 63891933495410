import React, { ReactNode } from 'react';

import styles from './Box.module.css';

interface BoxProps {
  children: ReactNode;
}
const Box = ({ children }: BoxProps) => (
  <div className={styles.box}>{children}</div>
);

export default Box;
