// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
// import firebaseui from 'react-firebaseui';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
// Then add the specific Firebase products that you want to use
import 'firebase/auth';

import Console from './console';
import { isDev } from './env';
import { setAuthToken } from './http';

let firebaseInitialised = false;
/** Initialise Firebase if not already initialised. */
export const safeFirebaseInit = () => {
  if (firebaseInitialised) return;
  const firebaseConfig = {
    apiKey: 'AIzaSyAG8MS89S6mn8z9Q_wwETXK7vHS5nk1dnM',
    authDomain: 'id-photography-api.firebaseapp.com',
    projectId: 'id-photography-api',
    storageBucket: 'id-photography-api.appspot.com',
    messagingSenderId: '988667735332',
    appId: '1:988667735332:web:ef32e7a919501feee157b1',
    measurementId: 'G-KQC9VCQMZK',
  };
  firebase.initializeApp(firebaseConfig);
  if (!isDev()) {
    firebase.analytics.isSupported().then((isSupported: boolean) => {
      if (isSupported) {
        firebase.analytics();
      }
    });
  }
  firebase.auth(); // Initialise user state immediately
  firebaseInitialised = true;
};
safeFirebaseInit();

export const getUsername = () => firebase.auth().currentUser?.displayName;

export const signOut = () => firebase.auth().signOut();

export const anonymousSignIn = () => {
  firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      // Signed in..
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((error) => {
      // let errorCode = error.code;
      // let errorMessage = error.message;
      // ...
    });
};

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    user
      .getIdToken(/* forceRefresh */ true)
      .then((idToken) => {
        const idTokenAbbreviated =
          idToken.length <= 10
            ? idToken
            : `${idToken.slice(0, 5)}...${idToken.slice(-5)}`;
        Console.log(`Setting auth token: ${idTokenAbbreviated}`);
        setAuthToken(idToken); // Send token to backend in Auth headers (via HTTPS)
      })
      .catch((error) => {
        Console.log(error);
        setAuthToken(null);
      });
  }
  if (user === null) {
    setAuthToken(null);
    // anonymousSignIn();
  }
});

// Temp variable to hold the anonymous user data if needed.
// const data = null;
// Hold a reference to the anonymous current user.
// const anonymousUser = firebase.auth().currentUser;
// Configure FirebaseUI.
export const getFirebaseUiConfig = () => ({
  // Whether to upgrade anonymous users should be explicitly provided.
  // The user must already be signed in anonymously before FirebaseUI is
  // rendered.
  // autoUpgradeAnonymousUsers: true,
  // Popup signin flow rather than redirect flow.
  //  signInFlow: 'popup',
  signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href)
    ? 'redirect'
    : 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,

      // Allow the user the ability to complete sign-in cross device,
      // including the mobile apps specified in the ActionCodeSettings
      // object below.
      forceSameDevice: false,
      // Used to define the optional firebase.auth.ActionCodeSettings if
      // additional state needs to be passed along request and whether to open
      // the link in a mobile app if it is installed.
      emailLinkSignIn() {
        return {
          // Additional state showPromo=1234 can be retrieved from URL on
          // sign-in completion in signInSuccess callback by checking
          // window.location.href.
          //          url: 'https://www.example.com/completeSignIn?showPromo=1234',
          // Custom FDL domain.
          //          dynamicLinkDomain: 'example.page.link',
          // Always true for email link sign-in.
          handleCodeInApp: true,
          // Whether to handle link in iOS app if installed.
          //          iOS: {
          //            bundleId: 'com.example.ios'
          //          },
          // Whether to handle link in Android app if opened in an Android
          // device.
          //          android: {
          //            packageName: 'com.example.android',
          //            installApp: true,
          //            minimumVersion: '12'
          //          }
        };
      },
    },
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
    // signInFailure callback must be provided to handle merge conflicts which
    // occur when an existing credential is linked to an anonymous user.
    /*    signInFailure(error: firebase.auth.AuthError) {
      // For merge conflicts, the error.code will be
      // 'firebaseui/anonymous-upgrade-merge-conflict'.
      if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
        return Promise.resolve();
      }
      // The credential the user tried to sign in with.
      const cred = error.credential as firebase.auth.AuthCredential;
      // Copy data from anonymous user to permanent user and delete anonymous
      // user.
      // ...
      firebase.auth().currentUser?.delete();
      // Finish sign-in after data is copied.
      return Promise.resolve(
        firebase.auth().signInWithCredential(cred)
      ).then<void>();
    }, */
  },
});
