import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import AnswerList from '../../components/admin/answerlist/AnswerList';
import NewAnswerForm from '../../components/admin/newanswerform/NewAnswerForm';
import Box from '../../components/box/Box';
import { newCacheBuster } from '../../util/math';
import paths, { EngineParams } from '../../util/paths';
import styles from './AdminPages.module.scss';

const ListAnswersPage = () => {
  const { engineId } = useParams<EngineParams>();
  const [listCacheBuster, setListCacheBuster] = useState(newCacheBuster());

  function handleCreateNewAnswer() {
    setListCacheBuster(newCacheBuster());
  }

  return (
    <>
      <NewAnswerForm
        engineId={engineId}
        answerId={null}
        onCreateNewAnswer={handleCreateNewAnswer}
        onEditExistingAnswer={() => {}}
        navigateOnSubmit={null}
      />
      <Box>
        <p className={styles.breadcrumbs}>
          <Link to={paths.engineAdmin}>Engine Admin</Link> /&nbsp;
          <Link to={paths.listEngines}>Engines</Link> /&nbsp;
          <Link to={paths.viewEngine(engineId)}>{engineId}</Link> /&nbsp;
        </p>
        <h1>Answers</h1>
        <p />
        <Link
          to={paths.newAnswer(engineId)}
          className={styles.btn}
          role="button"
        >
          New answer
        </Link>
        <p />
        <AnswerList engineId={engineId} cacheBuster={listCacheBuster} />
        <p />
      </Box>
    </>
  );
};

export { ListAnswersPage as default };
