import React, { ReactNode, useEffect, useState } from 'react';

import entryLogo from '../../res/logo/logo-entry.svg';
import Console from '../../util/console';
import { newCacheBuster } from '../../util/math';
import styles from './SplashScreen.module.css';

const LoadingMessage = () => {
  const cacheBuster = newCacheBuster();
  return (
    <div className={styles.SplashScreen}>
      <img
        src={`${entryLogo}?cb=${cacheBuster}`}
        className={styles.entryLogo}
        alt="Logo"
      />
    </div>
  );
};

interface SplashScreenProps {
  splashTime: number;
  backgroundAction: () => void;
  children: ReactNode;
}
const SplashScreen = ({
  splashTime,
  backgroundAction,
  children,
}: SplashScreenProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, splashTime);
    try {
      backgroundAction();
    } catch (err) {
      Console.log(err);
      setLoading(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [splashTime, backgroundAction]);

  if (loading) {
    return LoadingMessage();
  }
  return <>{children}</>;
};

export default SplashScreen;
